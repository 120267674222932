




























































































































































































































































































































































































































































import chipplyIcons from "@/chipply/ImportIcons";
import SelectorMixin from "@/components/SelectorMixin";
import { ITextValue } from "chipply-common";

import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IEcomOrderListArgs } from "@/chipply/interface/IEcomOrderListArgs";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import EcomOrderListFilters from "@/chipply/ecom-orders/EcomOrderListFilters";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import IEcomOrderResult from "@/chipply/ecom-orders/IEcomOrderResult";
import StoreGridMixin from "@/components/events/StoreGridMixin";
import TextHeading from "@/components/utility/TextHeading.vue";
import { Utils, WebHelper } from "chipply-common";
import IListOrderIdsResults from "@/chipply/ecom-orders/IListOrderIdsResults";
import ListOrderIdsArgs from "@/chipply/ecom-orders/ListOrderIdsArgs";

@Component({
    components: { TextHeading },
})
export default class OrderSelector extends mixins(StoreGridMixin, SelectorMixin) {
    public Utils = Utils;
    public chipplyIcons = chipplyIcons;
    public errorMessage: string | null = null;
    public events: object[] = [];
    public filters: EcomOrderListFilters = this.getDefaultFilters();

    @Prop({
        type: Array,
    })
    public initialSelectedIds!: number[];

    @Prop({
        type: Number,
    })
    public initialEventId!: number | undefined;

    @Prop({
        type: String,
    })
    public initialOrderStatus!: string | undefined;

    public organizations: Array<ITextValue<number>> = [];
    public rosterNameFilter: string | null = null;
    public items: IEcomOrderResult[] = [];
    public loading = false;
    public totalStores = 0;
    public showStoreOptionsDialog!: boolean;
    public showOrderFromDateMenu = false;
    public showOrderToDateMenu = false;
    public tableHeight: string | undefined = "180px";

    @Prop({
        type: Object,
    })
    public state!: IPageReferrerSettings | undefined;

    public selected: IEcomOrderResult[] = [];
    public hasCreated = false;

    @Prop({
        type: Number,
    })
    public catalogBatchId!: number;

    private orderIds: number[] = [];
    private wasLastPageSelected = false;
    private wasSelectAllChecked = false;
    private isSelectingNextPage = false;

    public pagination: IVuetifyTableOptions = {
        itemsPerPage: 20,
        page: 1,
        sortBy: ["name"],
        sortDesc: [],
    };
    public $refs!: {
        datatable: Vue;
    };

    public orderStatuses = [
        { text: "Paid", value: "paid" },
        { text: "Voided", value: "voided" },
        { text: "Failed", value: "failed" },
        { text: "Abandoned", value: "abandoned" },
        { text: "Refund Later", value: "refundlater" },
        { text: "Pay Later", value: "paylater" },
    ];

    public shippingTypes = [
        { text: "Shipping", value: "Shipping" },
        {
            text: "Organization Delivery",
            value: "Organization Delivery",
        },
        { text: "In Store Pickup", value: "In Store Pickup" },
    ];

    public storeStatuses = [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Scheduled", value: "scheduled" },
    ];
    public getItemIdPath(): string {
        return "orderId";
    }

    public async created() {
        this.filters.eventId = this.initialEventId;
        this.filters.orderStatus = this.initialOrderStatus;
        if (this.state) {
            this.filters = this.state.filters as EcomOrderListFilters;
            this.pagination = this.state.pagination;
        }

        this.hasCreated = true;
        await this.refreshData();
        const tableChild = this.$refs.datatable.$el.firstElementChild;
        if (tableChild) {
            tableChild.addEventListener("scroll", this.checkNextPage);
        }
    }

    public checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight &&
            !this.wasLastPageSelected
        ) {
            this.selectNextPage();
        }
    }

    protected getDefaultFilters(): EcomOrderListFilters {
        const filters = new EcomOrderListFilters();
        filters.eventId = this.initialEventId;
        filters.orderStatus = this.initialOrderStatus;
        return filters;
    }

    protected get computedHeaders() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return [
                {
                    align: "left",
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order# / Sale#",
                    value: "orderNumber",
                    width: "60px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order Status",
                    value: "orderStatus",
                    width: "90px",
                },

                { sortable: false, text: "Name", value: "billingName" },
                {
                    sortable: false,
                    text: "Organization/Store",
                    value: "organization",
                    width: "120px",
                },
            ];
        } else {
            return [
                { sortable: false, text: "", width: "50px", value: "select" },
                {
                    align: "left",
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order# / Sale#",
                    value: "orderNumber",
                    width: "60px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Order Status",
                    value: "orderStatus",
                    width: "90px",
                },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Store Status",
                    value: "storeStatus",
                    width: "90px",
                },
                { sortable: false, text: "Name", value: "billingName" },
                {
                    class: ["wrapHeader", "noPadding"],
                    sortable: false,
                    text: "Company Name",
                    value: "companyName",
                    width: "80px",
                },
                {
                    sortable: false,
                    text: "Email",
                    value: "billingEmail",
                    width: "80px",
                },
                {
                    sortable: false,
                    text: "Phone",
                    value: "billingPhone",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Organization/Store",
                    value: "organization",
                    width: "120px",
                },
                {
                    class: ["wrapHeader"],
                    sortable: false,
                    text: "Store Type",
                    value: "storeType",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Order Date",
                    value: "orderDate",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Est Ship Date",
                    value: "estimatedShipDate",
                    width: "100px",
                },
                {
                    sortable: false,
                    text: "Items",
                    value: "items",
                    width: "50px",
                },
                {
                    sortable: false,
                    text: "Total",
                    value: "orderTotal",
                    width: "50px",
                },
                {
                    sortable: false,
                    text: "Exported To Shipping",
                    value: "shippingExportDate",
                    width: "100px",
                },
            ];
        }
    }

    @Watch("initialEventId")
    protected onInitialEventIdChanged(ov: any, newValue: number | undefined) {
        this.filters.eventId = newValue;
    }

    @Watch("initialOrderStatus")
    protected onInitialOrderStatusChanged(ov: any, newValue: string | undefined) {
        this.filters.orderStatus = newValue;
    }

    @Watch("$vuetify.breakpoint.mdAndDown")
    protected resizeTable() {
        // This value may change based on the host.
        // I couldn't figure out a better way to make this work, open to improvement!
        // Without this the table will scroll past it's flex-basis and relative flex
        this.$nextTick(() => {
            let maxHeight = window.innerHeight;
            if (window.innerWidth > 960) {
                maxHeight -= 450;
            } else {
                maxHeight = maxHeight - 330 > 180 ? maxHeight - 330 : 180;
            }
            this.tableHeight = maxHeight + "px";
        });
    }

    protected async selectAll(args: { items: any[]; value: boolean }): Promise<void> {
        if (args.value === true) {
            this.wasSelectAllChecked = true;
        } else {
            this.wasSelectAllChecked = false;
        }
    }

    private buildArgs(): IEcomOrderListArgs {
        const args: IEcomOrderListArgs = {
            filters: this.filters,
            page: this.pagination.page,
            pageSize: this.pagination.itemsPerPage,
            isAdmin: false,
            newParentSearch: false,
        };
        args.filters.catalogBatchId = this.catalogBatchId;
        return args;
    }

    private clear() {
        this.filters = new EcomOrderListFilters();
    }

    private async refreshData() {
        this.pagination.page = 1;
        this.wasLastPageSelected = false;
        const unserializedData = await this.selectData();
        this.organizations = unserializedData.organizations;
        this.orderIds = [];
        this.items = unserializedData.orders;
        for (const order of this.items) {
            this.orderIds.push(order.orderId);
        }
        this.events = unserializedData.events;
        this.totalStores = unserializedData.totalCount;
        this.resetSelectedItems();
    }

    private async selectData(): Promise<any> {
        if (!this.hasCreated) {
            return;
        }

        this.loading = true;
        try {
            const urlParams = new URLSearchParams(window.location.search);
            let pageUrl = "/ng/ecom-orders.html";
            if (urlParams.has("errorMessage")) {
                pageUrl += "?errorMessage=" + encodeURIComponent(urlParams.get("errorMessage") as string);
            }

            const data = await WebHelper.postJsonData("/api/EcomOrder/GetOrders", this.buildArgs());
            const unserializedData = JSON.parse(data);
            if (unserializedData.orders.length >= this.pagination.itemsPerPage) {
                this.pagination.page++;
            } else {
                this.wasLastPageSelected = true;
            }
            return unserializedData;
        } finally {
            this.loading = false;
        }
    }
    private async selectNextPage(): Promise<IEcomOrderResult[]> {
        this.isSelectingNextPage = true;
        try {
            const unserializedData = await this.selectData();
            const addedOrders: IEcomOrderResult[] = [];
            const shouldSelectNewItems = this.isSelectAllIntended();
            if (unserializedData.orders.length > 0) {
                for (const order of unserializedData.orders) {
                    if (this.orderIds.indexOf(order.orderId) < 0) {
                        this.items.push(order);
                        this.orderIds.push(order.orderId);
                        addedOrders.push(order);
                    }
                    if (shouldSelectNewItems && this.items.indexOf(order) >= 0 && this.selected.indexOf(order) < 0) {
                        this.selected.push(order);
                    }
                }
                return addedOrders;
            }
            return [];
        } finally {
            this.isSelectingNextPage = false;
        }
    }

    private async selectAllIdsAsNeededAndClose(): Promise<void> {
        const baseUrl = "/api/EcomOrder/ListOrderIds";
        if (this.isSelectAllIntended()) {
            const args = new ListOrderIdsArgs();
            args.shouldSelectAll = true;
            if (this.catalogBatchId) this.filters.catalogBatchId = this.catalogBatchId;
            args.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, args);
            const results = JSON.parse(resultsText) as IListOrderIdsResults;

            for (const orderId of results.orderIds) {
                if (this.orderIds.indexOf(orderId) < 0) {
                    this.orderIds.push(orderId);
                }
                if (this.selectedIds.indexOf(orderId) < 0) {
                    this.selectedIds.push(orderId);
                }
            }
            this.close(true);
        } else {
            this.close(true);
        }
    }

    private isSelectAllIntended() {
        // For order selector it is enough to rely on all records being selected,
        // Usually we check to see if the select all button has been checked but since this control
        // can be opened more than once we won't rely on that here
        return this.areAllRecordsSelected();
    }

    private areAllRecordsSelected(): boolean {
        return this.items.length === this.selected.length;
    }
}
