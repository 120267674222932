








































































import CCard from "@/components/ui/CCard.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AsyncInteractionResult, AsyncInteractionViewModel } from "chipply-common";
@Component({
    components: {
        CCard,
    },
})
export default class CDecisionCard extends Vue {
    @Prop({
        type: String,
    })
    public title!: string;

    @Prop({
        type: Object,
    })
    public viewModel!: AsyncInteractionViewModel;

    @Prop({
        type: Array,
    })
    public buttons!: AsyncInteractionResult[];

    @Prop({
        default: null,
        type: String,
    })
    public cancelLabel!: string | null;
    @Prop({
        default: null,
        type: String,
    })
    public acceptLabel!: string | null;
    @Prop({
        default: null,
        type: String,
    })
    public maxHeight!: string | null;
    @Prop({
        default: false,
        type: Boolean,
    })
    public acceptDisabled!: boolean;
    public contentStyle = {
        overflowY: null as string | null,
        maxHeight: null as string | null,
    };
    public created() {
        if (this.maxHeight) {
            this.contentStyle.overflowY = "auto";
            this.contentStyle.maxHeight = this.maxHeight;
        }
    }
}
