






























import { Component, Vue, Prop } from "vue-property-decorator";
import CTextButton from "../ui/CTextButton.vue";
import OrderManagerViewModel from "@/chipply/ecom-orders/OrderManagerViewModel";

@Component({
    components: {
        CTextButton,
    },
})
export default class EcomOrdersFieldSelector extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    public vm!: OrderManagerViewModel;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isAdmin!: boolean;

    public toggleHeaderVisibility(header: any) {
        const tempHeader = this.vm.temporaryHeaders.find((h) => h.value === header.value);
        if (tempHeader) {
            tempHeader.visible = !tempHeader.visible;
        }
    }

    public async resetFilters() {
        this.vm.initializeHeaders();
        this.vm.updateHeadersBasedOnRole();
        this.vm.temporaryHeaders = this.vm.allHeaders.map((header) => ({ ...header }));
    }
}
