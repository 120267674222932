import ListUtils from "@/chipply/ListUtils";
import { IEditable, ITextValue, WebHelper } from "chipply-common";
import { ITextValueDisabled } from "chipply-common";
import Validation from "@/validation";
import { IHasOrganization } from "@/chipply/store/settings/IHasOrganization";
import OrganizationContactEditViewModel from "@/chipply/organization/OrganizationContactEditViewModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import OrganizationBranchEditViewModel from "@/chipply/organization/OrganizationBranchEditViewModel";
import OrganizationEditViewModel from "@/chipply/organization/OrganizationEditViewModel";
import OrganizationContactAddViewModel, {
    OrganizationContactAddType,
} from "@/chipply/organization/OrganizationContactAddViewModel";
import IOrganizationEditModel from "@/chipply/organization/IOrganizationEditModel";
import IStoreOrganizationContactModel from "@/chipply/organization/IStoreOrganizationContactModel";
import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import OrganizationStoreFee from "@/chipply/event/OrganizationStoreFee";
import StoreFeeViewModel from "@/chipply/event/StoreFeeViewModel";
import IFeeType from "@/chipply/event/IFeeType";
import CustomerStoreFeeViewModel from "@/chipply/event/CustomerStoreFeeViewModel";
import CustomerStoreFee from "@/chipply/event/CustomerStoreFee";

export class OrganizationHelper {
    public model: IHasOrganization | null = null;
    public pageVm: PageViewModel | null = null;

    public users: Array<ITextValueDisabled<number>> = [];
    public organizationContacts: OrganizationContactEditViewModel[] = [];
    public organizations: Array<ITextValueDisabled<number>> = [];
    public branches: Array<ITextValueDisabled<number>> = [];
    public orgBranches: Array<ITextValueDisabled<number>> = [];

    public editOrganizationViewModel: OrganizationEditViewModel | null = null;
    public editOrganizationBranchViewModel: OrganizationBranchEditViewModel | null = null;
    public editOrganizationContactViewModel: OrganizationContactEditViewModel | null = null;
    public addOrganizationContactViewModel: OrganizationContactAddViewModel | null = null;

    public feeTypes: Array<IFeeType> = [];
    public processes: Array<ITextValue<number>> = [];
    public editingFee: StoreFeeViewModel<any> | null = null;

    public stopFeeDelete: boolean | undefined = false;
    public headerText = "We are sorry...";
    public text = "The fee you are trying to delete is currently assigned to an order and cannot be deleted.";

    public constructor(model: IHasOrganization | null, pageVm: PageViewModel) {
        if (model && pageVm) {
            this.model = model;
            this.pageVm = pageVm;
        }
    }
    public get organizationDisabled() {
        const validatorResult = Validation.requireNotDisabled(this.organizations)(this.model!.orgId);
        return validatorResult !== true;
    }

    public get organizationRules() {
        return [Validation.requireNotDisabled(this.organizations), Validation.requireValueNonZeroList];
    }
    public async refreshBranches() {
        const branches = await this.getBranchList();
        this.branches = branches;
        this.orgBranches = ListUtils.filterUnusedDisabledValues(branches, this.model!.orgBranchId);
    }
    protected async getBranchList() {
        if (!this.model!.orgId) {
            return [];
        }
        return await WebHelper.getJsonData(`/api/Organization/GetOrganizationBranches/${this.model!.orgId}`);
    }
    public getOrganizationContactIds() {
        const filteredContactsIds: number[] = [];
        for (const contact of this.organizationContacts) {
            filteredContactsIds.push(contact.contactId);
        }
        return filteredContactsIds;
    }
    public async refreshContacts() {
        if (!this.model!.orgId) {
            this.users = [];
            return;
        }

        const users = await WebHelper.getJsonData(`/api/Organization/GetOrganizationContacts/${this.model!.orgId}`);

        const filteredContactsIds = this.getOrganizationContactIds();
        this.users = ListUtils.filterUnusedDisabledValues(users, ...filteredContactsIds);
    }
    public async organizationChanged() {
        this.pageVm!.loading = true;
        await this.refreshBranches();
        await this.refreshContacts();

        await this.model!.organizationChanged();

        this.pageVm!.loading = false;
    }

    public async performEditOrganizationBranch(edit: boolean, orgBranchId?: number) {
        this.pageVm!.loading = true;

        let organizationBranchEditModel: IOrganizationBranchEditModel | null = null;

        if (edit) {
            const results = await WebHelper.getJsonData(
                `/api/Organization/GetOrganizationBranchEdit?ids=${orgBranchId}`
            );
            organizationBranchEditModel = results[0];
        }

        this.pageVm!.loading = false;

        const addOrganizationViewModel = new OrganizationBranchEditViewModel(organizationBranchEditModel);
        addOrganizationViewModel.orgId = this.model!.orgId;
        this.editOrganizationBranchViewModel = addOrganizationViewModel;
        const result = await this.editOrganizationBranchViewModel.edit();
        this.editOrganizationBranchViewModel = null;

        if (result?.canceled) {
            return { canceled: true };
        }

        this.pageVm!.loading = true;
        const newId = JSON.parse(
            await WebHelper.postJsonData(
                "/api/Organization/PostOrganizationBranchEditModel",
                addOrganizationViewModel.toModel()
            )
        ) as number;

        addOrganizationViewModel.orgBranchId = newId;

        await this.refreshBranches();
        this.pageVm!.loading = false;
        return {
            canceled: false,
            orgBranchId: newId,
            model: addOrganizationViewModel.toModel(),
        };
    }
    //StoreSettings line 438
    public async addOrganizationContact() {
        const contactIds: number[] = [];
        this.model?.organizationContacts.forEach((v) => {
            contactIds.push(v.contactId);
        });

        const filteredUsers = ListUtils.filterListValues(this.users, ...contactIds);

        const addOrganizationContactViewModel = new OrganizationContactAddViewModel(filteredUsers);
        this.addOrganizationContactViewModel = addOrganizationContactViewModel;
        const result = await this.addOrganizationContactViewModel.edit();
        this.addOrganizationContactViewModel = null;

        if (result?.canceled) {
            return;
        }

        this.pageVm!.loading = true;

        if (addOrganizationContactViewModel.addType === OrganizationContactAddType.Existing) {
            const model = await this.getNewOrganizationContact(addOrganizationContactViewModel.existingId);
            const orgContact = new OrganizationContactEditViewModel(model);
            this.model!.organizationContacts.push(orgContact);
            if (this.model!.organizationContacts.length <= 3) {
                this.model!.shownOrganizationContacts.push(orgContact);
            }
        } else {
            const serverContactResp = await WebHelper.postJsonData(
                `/api/Organization/PostOrganizationContact/${this.model!.orgId}`,
                addOrganizationContactViewModel.toModel()
            );
            const serverContact = JSON.parse(serverContactResp);

            const orgContact = new OrganizationContactEditViewModel(serverContact);
            this.model!.organizationContacts.push(orgContact);
            if (this.model!.organizationContacts.length <= 3) {
                this.model!.shownOrganizationContacts.push(orgContact);
            }
        }
        this.pageVm!.loading = false;
    }

    protected async getNewOrganizationContact(contactId: number) {
        const model: IStoreOrganizationContactModel = await WebHelper.getJsonData(
            `/api/Organization/GetOrganizationContact/${contactId}`
        );
        return model;
    }
    // StoreSettings line 432
    public async editOrganizationBranch() {
        await this.performEditOrganizationBranch(true, this.model!.orgBranchId);
    }
    // StoreSettings line 433
    public async addOrganizationBranch() {
        const editResult = await this.performEditOrganizationBranch(false);
        if (editResult.canceled) {
            return;
        }
        this.model!.orgBranchId = editResult.orgBranchId!;
    }
    // StoreSettings line 419
    public async addOrganization() {
        await this.performEditOrganization(false);

        this.model!.orgBranchId = 0;
        this.model!.organizationContacts = [];
        this.branches = [];
        this.users = [];
    }
    // StoreSettings line 415
    public async editOrganization() {
        await this.performEditOrganization(true);
    }

    protected async performEditOrganization(edit: boolean) {
        this.pageVm!.loading = true;

        let organizationEditModel: IOrganizationEditModel | null = null;
        if (edit) {
            organizationEditModel = await WebHelper.getJsonData(
                `/api/Organization/GetOrganizationEditModel/${this.model!.orgId}`
            );
        }

        this.pageVm!.loading = false;

        const addOrganizationViewModel = new OrganizationEditViewModel(organizationEditModel);
        this.editOrganizationViewModel = addOrganizationViewModel;
        const result = await this.editOrganizationViewModel.edit();
        this.editOrganizationViewModel = null;

        if (result?.canceled) {
            return;
        }

        this.pageVm!.loading = true;
        const newId = JSON.parse(
            await WebHelper.postJsonData(
                "/api/Organization/SaveOrganizationEditModel",
                addOrganizationViewModel.toModel()
            )
        ) as number;

        const organizations = await WebHelper.getJsonData("/api/Organization/GetOrganizations");
        this.organizations = ListUtils.filterUnusedDisabledValues(organizations, newId);
        this.model!.organizations = this.organizations;
        this.model!.orgId = newId;
        this.pageVm!.loading = false;
    }
    // StoreSettings line 458
    public async editOrganizationContact(contact: OrganizationContactEditViewModel) {
        const editOrganizationContactViewModel = new OrganizationContactEditViewModel(contact.toModel());
        this.editOrganizationContactViewModel = editOrganizationContactViewModel;
        const result = await this.editOrganizationContactViewModel.edit();
        this.editOrganizationContactViewModel = null;

        if (result?.canceled) {
            return;
        }

        this.pageVm!.loading = true;
        const serverContactResp = await WebHelper.postJsonData(
            `/api/Organization/PostOrganizationContact/${this.model!.orgId}`,
            editOrganizationContactViewModel.toModel()
        );
        const serverContact = JSON.parse(serverContactResp);
        contact.updateFromModel(serverContact);

        if (contact.isPrimary) {
            for (const existingContact of this.model!.organizationContacts) {
                if (contact === existingContact) {
                    continue;
                }
                existingContact.isPrimary = false;
            }
        }

        this.pageVm!.loading = false;
    }
    // StoreSettings line 605
    public async addOrganizationFee() {
        const fee = new OrganizationStoreFeeViewModel(new OrganizationStoreFee(), this.processes, this.feeTypes);
        const result = await this.editFee(fee, false);
        if (result?.canceled) {
            return;
        }
        this.model?.organizationFees.push(fee);
    }
    //StoreSettings line 461
    public async removeContact(contact: OrganizationContactEditViewModel) {
        const index = this.model!.organizationContacts.indexOf(contact);
        if (index === -1) {
            return;
        }
        this.model?.organizationContacts.splice(index, 1);
        const shownIndex = this.model!.shownOrganizationContacts.indexOf(contact);
        if (shownIndex === -1) {
            return;
        }
        this.model?.shownOrganizationContacts.splice(shownIndex, 1);
    }
    // StoreSettings line 580
    public async editFee(fee: StoreFeeViewModel<IEditable>, showActive: boolean) {
        const editVm = fee.createEditViewModel();

        this.editingFee = editVm as StoreFeeViewModel<IEditable>;
        this.editingFee.showActive = showActive;
        const result = await editVm.edit();
        this.editingFee = null;

        if (result.canceled) {
            return result;
        }

        fee.apply(editVm);

        return result;
    }
    // StoreSettings line 567
    public async addCustomerFee() {
        const fee = new CustomerStoreFeeViewModel(new CustomerStoreFee(), this.feeTypes);
        const result = await this.editFee(fee, false);
        if (result?.canceled) {
            return;
        }
        this.model?.customerFees.push(fee);
    }
    // StoreSettings line 584

    public done() {
        this.stopFeeDelete = false;
    }

    public async deleteCustomerFee(fee: CustomerStoreFeeViewModel) {
        console.log(fee);

        if (fee.inUseCannotDelete) {
            this.stopFeeDelete = true;
            return;
        }

        this.model!.customerFees.splice(this.model!.customerFees.indexOf(fee), 1);
    }
    // StoreSettings line 621
    public async deleteOrganizationFee(fee: OrganizationStoreFeeViewModel) {
        this.model!.organizationFees.splice(this.model!.organizationFees.indexOf(fee), 1);
    }
}
