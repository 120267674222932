













import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class CRowActionButton extends Vue {
    @Prop({
        type: String,
    })
    public icon!: string;

    @Prop({
        type: String,
    })
    public label!: string;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: String,
    })
    public target!: string;

    @Prop({
        type: String,
    })
    public href!: string;

    @Prop({ type: Boolean, default: true })
    public showTitle!: boolean;
}
