





























































































































































import Validation from "@/validation";
import { SearchStoreProcessesViewModel } from "@/chipply/process/SearchStoreProcessesViewModel";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import ListLayout from "@/components/layout/ListLayout.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CDeleteDialog from "../dialogs/CDeleteDialog.vue";
import ScrollableContentMixin from "../ScrollableContentMixin";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import { IProcessListFilters } from "@/chipply/process/IStoreProcessDto";
import CDate from "@/components/ui/CDate.vue";
import { CDismissDialog } from "chipply-common-vue";
import CApiAutoComplete from "../ui/CApiAutoComplete.vue";
import { Autocompletes } from "@/chipply/Autocompletes";
import CDatePicker from "../ui/CDatePicker.vue";

@Component({
    components: {
        ListLayout,
        CToolbarBtn,
        CEditButton,
        CDeleteDialog,
        CDismissDialog,
        CDate,
        CDatePicker,
        CApiAutoComplete,
    },
})
export default class SearchStoreProcesses extends mixins(ScrollableContentMixin) {
    public vm = new SearchStoreProcessesViewModel();
    public name = "SearchStoreProcesses";

    @Prop({
        type: Number,
    })
    public storeId!: number;

    @Prop({
        type: Number,
    })
    public dealerId!: number;

    @Prop({
        type: Boolean,
    })
    public init!: boolean;

    public Validation = Validation;
    public isValid = false;
    public Autocompletes = Autocompletes;

    @Prop({
        type: Number,
        default: 500,
    })
    public containerHeight!: number;

    protected async created() {
        await this.initialize();
    }

    @Watch("init")
    protected async onInitChanged(nv: boolean, ov: boolean) {
        if (nv) {
            await this.initialize();
        }
    }

    public async initialize() {
        if (!this.storeId || !this.dealerId) {
            console.log("this.storeId or this.dealerId is empty");
            return;
        }

        this.vm.dealerId = this.dealerId;
        this.vm.storeId = this.storeId;
        await this.vm.onStoreIdChanged();
    }

    public validateStoreCloseDate(value: string) {
        return this.vm.validateStoreCloseDate();
    }

    public validateStoreOpenDate(value: string) {
        return this.vm.validateStoreOpenDate();
    }
}
