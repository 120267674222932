




































































































































































































































































































































































































































































































































import IBatchedCopyEventArgs from "@/chipply/event/IBatchedCopyEventArgs";
import chipplyIcons from "@/chipply/ImportIcons";
import { IGetStoreListArgs } from "@/chipply/interface/IGetStoreListArgs";
import { ITextValue } from "chipply-common";
import StoreListSortBy from "@/chipply/interface/StoreListSortBy";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import { SimpleAsyncInteractionViewModel, Utils, WebHelper, typeDependencies } from "chipply-common";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import EcomOrderListFilters from "@/chipply/ecom-orders/EcomOrderListFilters";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import IEcomOrderResult from "@/chipply/ecom-orders/IEcomOrderResult";
import StoreGridMixin from "@/components/events/StoreGridMixin";
import TextHeading from "@/components/utility/TextHeading.vue";
import CDeleteDialog from "../dialogs/CDeleteDialog.vue";
import ShipStationSettings from "@/chipply/view-model/dealer/ShipStationSettings";
import IShippingStore from "@/chipply/dealer/IShippingStore";
import Validation from "@/validation";
import ListLayout from "../layout/ListLayout.vue";
import OrderManagerViewModel from "@/chipply/ecom-orders/OrderManagerViewModel";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import EcomOrdersFiltersEditor from "./EcomOrdersFiltersEditor.vue";
import EcomOrdersFieldSelector from "./EcomOrdersFieldSelector.vue";
import CRowActionButton from "../ui/CRowActionButton.vue";
import EcomOrdersNotesTransactions from "./EcomOrdersNotesTransactions.vue";
import EditOrderNotesViewModel from "@/chipply/manage-orders/EditOrderNotesViewModel";
import { OptionDrawerType } from "@/chipply/ecom-orders/OrderManagerViewModel";
import CIconMail from "./CIconMail.vue";
import { EventBus } from "@/chipply/EventBus";
import CDecisionCard from "../ui/CDecisionCard.vue";

interface ISpreadsheetFilters {
    vendorId: number | null;
    filter: string | null;
    categoryIds: number[];
    processId: number | undefined;
}

@Component({
    components: {
        LongRunningOperationDialog,
        TextHeading,
        CDeleteDialog,
        ListLayout,
        CToolbarBtn,
        EcomOrdersFiltersEditor,
        EcomOrdersFieldSelector,
        CRowActionButton,
        EcomOrdersNotesTransactions,
        CDecisionCard,
    },
})
@typeDependencies({
    types: {
        ShipStationSettings,
    },
})
export default class EcomOrdersGrid extends mixins(StoreGridMixin) {
    public $refs!: {
        listlayout: Vue;
        filtersEditor: EcomOrdersFiltersEditor;
        fieldSelector: EcomOrdersFieldSelector;
    };

    public OptionDrawerType = OptionDrawerType;
    public vm = new OrderManagerViewModel();
    public notesViewModel = new EditOrderNotesViewModel();
    public name = "EcomOrdersGrid";
    public Utils = Utils;
    public chipplyIcons = chipplyIcons;
    public events!: object[];

    @Prop({
        type: Number,
    })
    public initialEventId!: number | undefined;

    @Prop({
        type: String,
    })
    public initialOrderStatus!: string | undefined;

    @Prop({
        type: Boolean,
    })
    public isAdmin!: boolean;

    public rosterNameFilter!: string | undefined;
    public items!: IEcomOrderResult[];
    public isShippingIntegrationAvailable!: boolean;
    public totalStores!: number;
    public showStoreOptionsDialog!: boolean;
    public showOrderFromDateMenu!: boolean;
    public showOrderToDateMenu!: boolean;
    public showCopyDialog!: boolean;
    public storeCopyArgs!: IBatchedCopyEventArgs;
    public tableHeight!: string | undefined;

    @Prop({
        type: Object,
    })
    public state!: IPageReferrerSettings | undefined;

    public selected!: IEcomOrderResult[];
    public longRunningMessage: string | null = null;

    public get isIndeterminate() {
        const selectedCount = this.vm.items.filter((item) => item.selected).length;
        return selectedCount > 0 && selectedCount < this.vm.items.length;
    }

    public toggleSelectAll(value: boolean) {
        this.vm.items.forEach((item) => {
            item.selected = value;
        });
        this.vm.wasSelectAllChecked = value;
    }

    @Prop({
        default: 0,
        type: Number,
    })
    public catalogBatchId!: number;

    @Prop({ default: false, type: Boolean })
    public shouldScopeToCatalogBatch!: boolean;

    public validation = Validation;

    public async created() {
        this.vm.isAdmin = this.isAdmin;
        this.vm.wasSelectAllChecked = false;

        if (this.initialEventId) {
            const storeDetails = await WebHelper.getJsonData(`/api/Events/Settings/${this.initialEventId}`);

            this.vm.filters.saleOrder = storeDetails.store.salesOrder.toString();
            this.vm.filtersViewModel.filterItems.saleOrder.value = storeDetails.store.salesOrder.toString();
        }

        if (this.initialOrderStatus) {
            this.vm.filters.orderStatus = this.initialOrderStatus;
            this.vm.filtersViewModel.filterItems.orderStatus.selected = {
                text: String(this.initialOrderStatus),
                value: this.initialOrderStatus,
            };
        }

        if (this.catalogBatchId) {
            this.vm.filters.catalogBatchId = this.catalogBatchId;
        }

        if (this.state) {
            this.vm.filters = { ...this.vm.filters, ...(this.state.filters as EcomOrderListFilters) };
            this.vm.pagination = this.state.pagination;
        }
        this.vm.hasCreated = true;
        await this.vm.fetchDealerData();
        await this.vm.list(true);
        this.vm.updateHeadersBasedOnRole();
    }

    public mounted() {
        EventBus.$on("show-filter-editor", this.vm.openEcomOrdersFilterEditor);
    }

    public beforeDestroy() {
        EventBus.$off("show-filter-editor", this.vm.openEcomOrdersFilterEditor);
    }

    public data() {
        return {
            Utils,
            errorMessage: undefined,
            events: [],
            isShippingIntegrationAvailable: false,
            items: [],
            newStoreName: undefined,
            rosterNameFilter: undefined,
            selected: [],
            showCopyDialog: false,
            showOrderFromDateMenu: false,
            showOrderToDateMenu: false,
            showPrintSalesOrderWarning: false,
            showSendToShippingWarning: false,
            showShipStationStoreSelection: false,
            showStoreOptionsDialog: false,
            storeCopyArgs: undefined,
            tableHeight: "180px",
            totalStores: 0,
        };
    }

    public optionsAccepted() {
        this.vm.allHeaders = this.vm.temporaryHeaders.map((header) => ({ ...header }));
        this.vm.list(true);
    }
    public optionsCanceled() {
        this.vm.temporaryHeaders = this.vm.allHeaders.map((header) => ({ ...header }));
        const fieldSelector = this.$refs.fieldSelector as EcomOrdersFieldSelector;
        if (fieldSelector) {
            fieldSelector.vm.temporaryHeaders = this.vm.allHeaders.map((header) => ({ ...header }));
        }
        this.vm.filtersViewModel.clearAll();
    }

    public resetFilters() {
        this.$nextTick(() => {
            const filtersEditor = this.$refs.filtersEditor;
            if (filtersEditor) {
                filtersEditor.resetFilters();
            }
        });
    }

    public clearSuccessMessage(newVal: boolean) {
        if (newVal === false) {
            this.vm.successMessage = null;
        }
    }

    public clearSelection() {
        this.vm.items.forEach((item) => {
            item.selected = false;
            this.$set(item, "selected", false);
        });
    }

    @Watch("catalogBatchId")
    protected catalogBatchIdChanged(ov: any, newValue: number | undefined) {
        this.vm.filters.catalogBatchId = newValue;
    }

    @Watch("initialEventId")
    protected onInitialEventIdChanged(ov: any, newValue: number | undefined) {
        this.vm.filters.eventId = newValue;
    }

    @Watch("initialOrderStatus")
    protected onInitialOrderStatusChanged(ov: any, newValue: string | undefined) {
        this.vm.filters.orderStatus = newValue;
    }

    public editItem(item: IEcomOrderResult) {
        const url = this.getOrderUrl(item);
        window.location.href = url;
    }

    public getOrderUrl(item: IEcomOrderResult) {
        const baseUrl = `/ng/edit-order.html?oid=${item.orderId}`;
        const version = `${Math.floor(Math.random() * 100000)}`;
        if (this.shouldScopeToCatalogBatch)
            return `${baseUrl}&should-scope-to-catalog-batch=${this.shouldScopeToCatalogBatch}&v=${version}`;
        else return `${baseUrl}&v=${version}`;
    }

    public expandRow(item: IEcomOrderResult) {
        const currentExpandedItems = this.vm.dataTableExpandedItems;
        if (currentExpandedItems.length > 0) {
            this.vm.dataTableExpandedItems = [];
        }
        this.vm.dataTableExpandedItems.push(item);
    }
}
