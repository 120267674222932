


















































































































































import Vue from "vue";
import Component from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { Utils } from "chipply-common";
import CApiAutoComplete from "@/components/ui/CApiAutoComplete.vue";
import { Autocompletes } from "@/chipply/Autocompletes";
import { Prop } from "vue-property-decorator";
import HtmlTextField from "@/components/HtmlTextField.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import CMoney from "@/components/ui/CMoney.vue";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";
import CChildPane from "@/components/ui/CChildPane.vue";
import Sortable, { SortableEvent } from "sortablejs";
import IProductDetailSizeModel from "@/chipply/products/edit/IProductDetailSizeModel";
import EditProductDetailSizesViewModel from "@/chipply/products/edit/EditProductDetailSizesViewModel";
import ListLayout from "@/components/layout/ListLayout.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CDeleteDialog from "@/components/dialogs/CDeleteDialog.vue";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";

@Component({
    components: {
        ListLayout,
        CChildPane,
        EventProductColorSizeGrid,
        CMoney,
        HtmlEditor,
        HtmlTextField,
        CApiAutoComplete,
        CToolbarBtn,
        EditLayout,
        CDeleteButton,
        CDeleteDialog,
        CSaveDialog,
    },
})
export default class EditProductDetailSizes extends Vue {
    public chipplyIcons = chipplyIcons;
    public showEventProductColorDialog = false;
    public items: IProductDetailSizeModel[] = [];
    public Utils = Utils;
    public tableHeight: string | null = null;
    public Autocompletes = Autocompletes;

    @Prop({
        default: new EditProductDetailSizesViewModel(),
        type: Object,
    })
    public vm!: EditProductDetailSizesViewModel;

    public closeChildSizes() {
        this.$emit("close");
    }

    public $refs!: {
        searchPanel: HTMLDivElement;
    };

    public tableDataChanged() {
        this.vm.isDirty = true;
    }

    public preventNonNumericInput($event: KeyboardEvent) {
        Utils.preventNonNumericInput($event);
    }

    public async created() {
        await this.vm.getProductDetailSizes();
        if (this.vm.isAdminUser) {
            await this.vm.getStandardSizes();
        }

        this.$nextTick(() => {
            this.initializeDragAndDrop("sizeTable");
        });
    }
    protected initializeDragAndDrop(tableId: string) {
        const table = document.getElementById("sizeTable");
        const tableBody = table!.querySelector("tbody");

        Sortable.create(tableBody!, {
            handle: ".dragHandle",
            //filter: ".workOrderNotes",
            preventOnFilter: false,
            onStart: (event: SortableEvent) => {},
            onEnd: (event: SortableEvent) => {
                const oldIndex = event.oldIndex!;
                const newIndex = event.newIndex!;
                const tempArray: IProductDetailSizeModel[] = [];
                tempArray.push(...this.vm.existingSizes!);

                const rowSelected = tempArray.splice(oldIndex, 1)[0];
                tempArray.splice(newIndex, 0, rowSelected);
                this.vm.existingSizes! = [];
                // Goofy but necessary to correctly redraw after drag and drop
                this.$nextTick(() => {
                    this.vm.existingSizes = tempArray;
                    this.tableDataChanged();
                });
            },
        });
    }

    public get sizeHeader() {
        if (this.vm.isAdminUser) {
            return [
                { text: "", value: "dragHandle" },
                { text: "Size Name", value: "sizeName" },
                { text: "Standard Size", value: "standardSizes.standardSizeName" },
                { text: "Additional Cost", value: "additionalCost" },
                { text: "", value: "delete" },
            ];
        } else {
            return [
                { text: "", value: "dragHandle" },
                { text: "Size Name", value: "sizeName" },
                { text: "Additional Cost", value: "additionalCost" },
                { text: "", value: "delete" },
            ];
        }
    }
}
