import { render, staticRenderFns } from "./ShipStationSettingsEditor.vue?vue&type=template&id=a779a5d6&scoped=true"
import script from "./ShipStationSettingsEditor.vue?vue&type=script&lang=ts"
export * from "./ShipStationSettingsEditor.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a779a5d6",
  null
  
)

export default component.exports