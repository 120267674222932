





































































































































































import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import ListLayout from "@/components/layout/ListLayout.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { UsersViewModel } from "@/chipply/dealer/UsersViewModel";
import EditUser from "@/components/dealer/EditUser.vue";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import UserLevel from "@/chipply/UserLevel";
import { Watch, Prop } from "vue-property-decorator";
import NavigationArea from "@/chipply/NavigationArea";
import CRowActionButton from "../ui/CRowActionButton.vue";
import AddOrganizationContact from "../organization/AddOrganizationContact.vue";

@Component({
    components: {
        ListLayout,
        CToolbarBtn,
        CDecisionCard,
        CEditButton,
        TextHeading,
        EditUser,
        CRowActionButton,
        AddOrganizationContact,
    },
})
export default class Users extends mixins(UserInfoMixin) {
    public icons = chipplyIcons;
    public vm = new UsersViewModel();
    public name = "Users";

    @Prop({
        type: Number,
    })
    public companyId!: number;

    @Prop({
        type: Number,
    })
    public dealerId!: number;

    @Prop({
        type: Array,
    })
    public userLevelIds!: number[];

    @Prop({
        type: Boolean,
    })
    public hideLogin!: boolean;

    @Prop({
        type: Boolean,
    })
    public isDealerAdminScreen!: boolean;

    public NavigationArea = NavigationArea;
    public isAdmin = false;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    @Prop({
        type: Number,
    })
    public orgId!: number | null;

    public declare $refs: {
        usersTable: ListLayout;
    };

    public async created() {
        await this.userInfoCreated;
        this.vm.loggedInUserLevelId = this.userInfo.userLevel;
        this.isAdmin = this.userInfo.userLevel == UserLevel.Admin;
        this.vm.updateUserLevels(this.userLevelIds);
        this.vm.orgId = this.orgId;
        if (this.companyId) {
            this.vm.companyId = this.companyId;
            if (this.dealerId > 0) {
                this.vm.dealerId = this.dealerId;
            }
            await this.vm.refreshData();
        }
    }

    @Watch("dealerId")
    protected dealerIdChanged() {
        if (this.dealerId > 0) {
            this.vm.dealerId = this.dealerId;
        }
    }

    public convertDate(dateString: any) {
        const result = new Date(Date.parse(dateString)).toLocaleDateString("en-US", { timeZone: "UTC" });
        return result == "Invalid Date" ? "N/A" : result;
    }

    public get computedAddButtonLabel() {
        return this.vm.area == NavigationArea.OrganizationDetails ? "Add Contact" : "Add User";
    }

    public get computedShowBack() {
        return this.vm.area == NavigationArea.OrganizationDetails;
    }

    public get computedEditButtonLabel() {
        return this.vm.area == NavigationArea.OrganizationDetails ? "Edit Contact" : "Edit User";
    }

    public rowClicked(row: any) {
        this.vm.editUser(row.userId);
    }

    public clearSuccessMessage(newVal: boolean) {
        if (newVal === false) {
            this.vm.successMessage = null;
        }
    }
}
