import ICustomerStoreFee from "@/chipply/event/ICustomerStoreFee";

export default class CustomerStoreFee implements ICustomerStoreFee {
    public eventFeeTypeId = 0;
    public feeCost: number | null = null;
    public feeId = 0;
    public feeName = "";
    public isActive = true;
    public inUseCannotDelete = true;
}
