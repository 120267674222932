































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    name: "CSummaryCard",
})
export default class CSummaryCard extends Vue {
    @Prop({
        type: String,
    })
    public label!: string;

    @Prop({
        type: [String, Number],
    })
    public value!: string | number;

    @Prop()
    loading!: boolean;
    @Prop()
    largeScreen!: boolean;
    @Prop()
    mediumScreen!: boolean;
    @Prop()
    smallScreen!: boolean;

    public get cardClass() {
        if (this.largeScreen) return "cardLarge textLarge";
        if (this.mediumScreen) return "cardMedium textMedium";
        if (this.smallScreen) return "cardSmall textSmall";
        return "";
    }
}
