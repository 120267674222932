




























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        prop: "value",
        event: "change",
    },
})
export default class CChip extends Vue {
    @Prop({
        type: String,
        default: "",
    })
    public title!: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    public enabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public close!: boolean;

    @Prop({
        type: String,
        default: "",
    })
    public closeIcon!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public value!: boolean;

    public isSelected = this.value;

    @Prop({
        type: String,
    })
    public icon!: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    public changeOnClick!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public dark!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isFilterBanner!: boolean;

    public handleClick() {
        if (this.enabled) {
            if (this.changeOnClick) {
                this.isSelected = !this.isSelected;
            }
            this.$emit("change", this.isSelected);
        }
    }
    @Watch("value")
    public valueChange() {
        this.isSelected = this.value;
    }

    public handleCloseClick(event: Event) {
        if (this.enabled) {
            this.$emit("click:close", event);
        }
    }
}
