

































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import TextHeading from "@/components/utility/TextHeading.vue";

@Component({
    components: { TextHeading },
})
export default class StoreFlyerReport extends Vue {
    public $refs!: {
        iframeDiv: HTMLDivElement;
    };

    public name = "StoreFlyerReport";

    @Prop({
        type: Number,
    })
    public eventId!: number;

    public data() {
        return {};
    }

    @Watch("eventId")
    public eventIdChanged() {
        this.updateIFrame();
    }

    public created() {
        this.updateIFrame();
    }

    protected updateIFrame() {
        const pdfPath = `/api/Reports/StoreFlyer/Pdf/${this.eventId}`;
        this.$refs.iframeDiv.innerHTML = `<iframe src="${pdfPath}" style="margin: 10px; width: calc(100% - 40px); height: 700px"></iframe>`;
    }
    get downloadPdfLink() {
        return `/api/Reports/StoreFlyer/Pdf/${this.eventId}`;
    }
}
