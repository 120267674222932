




















































import Vue from "vue";
import Component from "vue-class-component";
import ITreeItem from "@/chipply/interface/i-tree-item";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";

@Component({
    model: {
        prop: "ids",
        event: "change",
    },
})
export default class CategoryTreeSelector extends Vue {
    public name = "CategoryTreeSelector";
    public selectionType = "independent";

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: Array,
    })
    public treeItems!: ITreeItem[];

    public selectedIds!: number[];
    public selectAllChecked!: boolean;
    public treeIds!: number[];
    public selectedItemsDisplay!: string;

    @Prop({
        type: String,
    })
    public defaultText!: string;

    @Prop({
        type: Array,
    })
    public ids!: number[];

    public search = "";
    public filteredTreeItems: ITreeItem[] = [];
    public initialTreeViewInput = true;

    @Prop({
        type: Boolean,
    })
    public validate!: boolean;

    public chipplyIcons = chipplyIcons;
    public menuVisible = false;
    public expandedItemIds: number[] = [];

    @Prop({
        default: "200px",
    })
    public popupMaxHeight!: string;

    public $refs!: {
        treeView: Vue;
    };

    public data() {
        return {
            selectedItemsDisplay: undefined,
            selectAllChecked: false,
            selectedIds: this.ids,
            treeIds: [],
        };
    }

    public get filteredItems() {
        debugger;
        if (!this.search) {
            return this.treeItems;
        }
        const searchLower = this.search.toLowerCase();
        return this.treeItems.filter(
            (item) =>
                item.name.toLowerCase().includes(searchLower) ||
                (item.children && item.children.some((child) => child.name.toLowerCase().includes(searchLower)))
        );
    }

    public created() {
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
        this.filteredTreeItems = this.treeItems;
        this.expandAllItems();
    }

    public mounted() {
        window.addEventListener("scroll", this.handleScroll);

        const container = document.querySelector(".main");
        if (container) {
            container.addEventListener("scroll", this.handleScroll);
        }
    }

    public beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);

        const container = document.querySelector(".main");
        if (container) {
            container.removeEventListener("scroll", this.handleScroll);
        }
    }

    private handleScroll() {
        if (this.menuVisible) {
            this.menuVisible = false;
        }
    }

    @Watch("ids")
    public onIdsChanged() {
        this.initialTreeViewInput = true;
        this.selectedIds = this.ids;
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
    }

    public treeViewInput(selectedIds: number[]) {
        this.selectedIds = selectedIds;
        this.updateSelectedItemsDisplay();
    }

    public updateSelectedItemsDisplay() {
        this.$emit("change", this.selectedIds);

        if (this.selectedIds.length === 0) {
            this.selectedItemsDisplay = this.defaultText || "";
            return;
        }

        const displayLimit = 35;
        let displayText = "";
        let othersCount = 0;
        const selectedNames = this.selectedIds
            .map((catId) => this.findCategoryName(this.treeItems, catId))
            .filter((name) => name);

        for (let i = 0; i < selectedNames.length; i++) {
            const nameLength = selectedNames[i]!.length;
            if (displayText.length + nameLength + 2 > displayLimit) {
                othersCount = selectedNames.length - i;
                break;
            }
            displayText += (i > 0 ? "; " : "") + selectedNames[i];
        }

        if (othersCount > 0) {
            displayText += ` (+${othersCount} others)`;
        }

        this.selectedItemsDisplay = displayText;
    }

    public constructDisplayText(treeItems: ITreeItem[], selectedIds: number[]): string {
        const selectedNames = selectedIds
            .map((catId) => {
                const name = this.findCategoryName(treeItems, catId);
                return name || "";
            })
            .filter((name) => name);
        return selectedNames.join("; ");
    }

    get display() {
        if (this.selectedItemsDisplay) {
            return this.selectedItemsDisplay;
        }
        return this.defaultText;
    }

    public clearSelection() {
        this.selectedIds = [];
        this.treeIds = [];
        this.selectAllChecked = false;
        this.updateSelectedItemsDisplay();
    }

    public isValid() {
        if (!this.validate) {
            return true;
        }
        return this.selectedIds && this.selectedIds.length > 0;
    }

    public filterTreeItems(): void {
        const searchTerm = (this.search || "").trim().toLowerCase();

        const filterItems = (items: ITreeItem[]): ITreeItem[] => {
            return items.reduce<ITreeItem[]>((acc, item) => {
                let shouldAddItem = item.name.toLowerCase().startsWith(searchTerm);
                let filteredChildren: ITreeItem[] = [];
                if (item.children && item.children.length > 0) {
                    filteredChildren = filterItems(item.children);
                    if (filteredChildren.length > 0 || shouldAddItem) {
                        const itemWithFilteredChildren = { ...item, children: filteredChildren };
                        acc.push(itemWithFilteredChildren);
                    }
                } else if (shouldAddItem) {
                    acc.push(item);
                }
                return acc;
            }, []);
        };

        this.filteredTreeItems = filterItems(this.treeItems);

        if (!searchTerm) {
            this.expandAllItems();
        }
    }

    private findCategoryName(categories: ITreeItem[], categoryId: number): string | null {
        for (const cat of categories) {
            if (cat.id === categoryId) {
                return cat.name;
            }
            if (cat.children) {
                const name = this.findCategoryName(cat.children, categoryId);
                if (name) {
                    return name;
                }
            }
        }
        return null;
    }

    public checkAll(checked: boolean) {
        const collectIds = (items: ITreeItem[]): number[] => {
            const ids: number[] = [];
            for (const item of items) {
                ids.push(item.id);
                if (item.children) {
                    ids.push(...collectIds(item.children));
                }
            }
            return ids;
        };

        let allCats: number[] = [];

        if (checked) {
            allCats = collectIds(this.treeItems);
        }

        this.treeIds = allCats;
        this.selectedIds = allCats;
        this.selectAllChecked = checked;
        this.updateSelectedItemsDisplay();
    }

    private expandAllItems() {
        const collectIds = (items: ITreeItem[]): number[] => {
            const ids: number[] = [];
            for (const item of items) {
                ids.push(item.id);
                if (item.children) {
                    ids.push(...collectIds(item.children));
                }
            }
            return ids;
        };

        this.expandedItemIds = collectIds(this.treeItems);
    }
}
