import OrderTotals from "@/chipply/event/OrderTotals";

export default class StoreTotals {
    public netTotal = 0;
    public productCount = 0;
    public organizationFeeTotal = 0;
    public fees: Array<{
        name: string;
        cost: number;
    }> = [];

    public manualAdjustmentOrders: Array<{
        manualAdjustmentOrder: number;
        customerName: string;
        manualAdjustmentAmount: number;
    }> = [];

    public handlingFees: Array<{
        name: number;
        cost: number;
    }> = [];

    public couponTotals: Array<{ name: string; cost: number }> = [];
    public orderTotals!: OrderTotals;

    public constructor() {
        this.orderTotals = new OrderTotals();
    }
}
