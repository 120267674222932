





















import chipplyIcons from "@/chipply/ImportIcons";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { AsyncInteractionWithDataViewModel, SimpleAsyncInteractionWithDataViewModel } from "chipply-common";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        CDecisionCard,
    },
})
export default class CSuccessOrFailureCard extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Object,
    })
    public viewModel!:
        | AsyncInteractionWithDataViewModel<boolean>
        | SimpleAsyncInteractionWithDataViewModel<boolean>
        | null;
}
