




































import chipplyIcons from "@/chipply/ImportIcons";
import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import Component, { mixins } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import { WebHelper, SimpleAsyncInteractionWithDataViewModel, DateUtils } from "chipply-common";
import { ICustomerEmailBodyDto } from "@/chipply/emails/ICustomerEmailDto";

@Component({})
export default class EmailViewer extends mixins(ScrollableContentMixin) {
    public chipplyIcons = chipplyIcons;
    public name = "EmailViewer";

    @Prop({
        type: Number,
    })
    public emailId!: number;

    @Prop({
        type: Number,
    })
    public storeId!: number;

    public DateUtils = DateUtils;
    public loading = false;
    public vm: SimpleAsyncInteractionWithDataViewModel<ICustomerEmailBodyDto> =
        new SimpleAsyncInteractionWithDataViewModel();

    // @ts-ignore
    public $refs!: {
        iframeRef: HTMLIFrameElement;
    };

    public created() {
        this.viewEmailDetails(this.storeId, this.emailId);
    }

    @Watch("emailId")
    public onEmailIdChanged() {
        this.viewEmailDetails(this.storeId, this.emailId);
    }

    @Watch("storeId")
    public onStoreIdChanged() {
        this.viewEmailDetails(this.storeId, this.emailId);
    }

    public formatDate(date: string) {
        const obj = DateUtils.parseDateString(date);
        return obj.dateString + " " + obj.timeString;
    }

    protected async viewEmailDetails(storeId: number, emailId: number) {
        if (!storeId || !emailId) return;
        this.loading = true;
        try {
            const results = (await WebHelper.getJsonData(
                `/api/Event/Email/CustomerEmailBody/${storeId}/${emailId}`
            )) as ICustomerEmailBodyDto;
            this.vm.headerText =
                "Email Details - Order: " + (parseInt(results.ecomOrderId) > 0 ? results.ecomOrderId : "N/A");
            this.vm.html = results.body;
            this.vm.data = results;
            this.applyEmailBody();
            this.loading = false;
            await this.vm.interact();
            this.$emit("done");
        } finally {
            this.loading = false;
        }
    }

    public applyEmailBody() {
        setTimeout(() => {
            const iframeRef = this.$refs.iframeRef;
            if (iframeRef.contentWindow) {
                var doc = iframeRef.contentWindow.document;
                if (this.vm && this.vm.html) {
                    const html = this.vm.html;
                    doc.open().write(html);
                    doc.close();
                } else {
                    const defaultHtml = `<html><body></body></html>`;
                    doc.open().write(defaultHtml);
                    doc.close();
                }
            }
        });
    }

    public okClick() {
        this.$emit("done");
    }
}
