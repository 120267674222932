














import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CDeleteButton extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Boolean,
    })
    public xSmall: boolean | undefined;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    public isXSmall = false;
    public isSmall = true;

    public mounted() {
        if (this.xSmall) {
            this.isSmall = false;
            this.isXSmall = true;
        } else {
            this.isSmall = true;
        }
    }
}
