









import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class ErrorReporter extends Vue {
    public name = "ErrorReporter";

    @Prop({
        type: String,
    })
    public errorMessage!: string;

    @Prop({
        type: Boolean,
    })
    public value!: boolean;

    public opened!: boolean;

    public data() {
        return {
            opened: false,
        };
    }

    @Watch("value")
    protected onValueChanged(nv: boolean) {
        this.opened = nv;
    }

    @Watch("opened")
    protected snackbarOpenedChanged(nv: boolean) {
        this.$emit("change", nv);
    }
}
