import ProcessInfo from "@/chipply/reports/ProcessInfo";

export default class WorkOrderReportToggleOptions {
    public includeDealerName = true;
    public includeSalesPersonName = true;
    public includeDealerLogo = true;
    public includeAllProcess = true;
    public includeAccountingSummary = true;
    public includeAccountingSummaryNotes = true;
    public includeAccountingSummaryManualAdjustmentsLeft = false;
    public includeAccountingSummaryManualAdjustmentsRight = false;
    public includeHandlingFeesExpanded: boolean | undefined = false;
    public includeAccountingSummaryOperationalFees = false;
    public includeAccountingSummaryPromoCodes = false;
    public includeProducts = true;

    public processes: ProcessInfo[] = [];

    public includeCostOnly = false;
    public includeProductThumbnails = false;
    public includeProductCostOnly = false;
    public includeProductCost = false;
    public includeProductPrice = true;

    public includeProductAndProcess = false;

    public includeSellPrice = false;
    public includeFundraising = false;
    public includeOversizeCharges = false;
    public includeOptionCharges = false;
    public includeProductSummary = true;
    public includeArtwork = true;
    public includeArtworkColorNames = true;
    public includeArtworkProductImages = true;
    public includeOptions = true;
    public includeOptionThumbnails = false;

    public noAdditionalColumns = false;
    public costAndPrice = false;
    public priceOnly = false;
    public costOnly = false;
    public priceSummary = false;

    public showSizeChargesDetails = false;
    public showProcessComponentsDetails = true;
}
