





























import ITaxSetting from "@/chipply/ITaxSetting";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/validation";
import { TaxType, Utils } from "chipply-common";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class TaxTypeSelection extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    public enableCustom!: boolean;

    public name = "TaxTypeSelection";
    public TaxType = TaxType;
    public Utils = Utils;
    public Validation = Validation;

    public get taxTypes() {
        if (this.enableCustom) {
            return [
                { text: "Auto", value: 0 },
                { text: "Flat Percentage", value: 1 },
                { text: "Tax Exempt", value: 2 },
                { text: "Custom", value: 3 },
            ];
        }
        return [
            { text: "Auto", value: 0 },
            { text: "Flat Percentage", value: 1 },
            { text: "Tax Exempt", value: 2 },
        ];
    }

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: Object,
    })
    public value!: ITaxSetting;

    public modelValue: ITaxSetting = {
        taxRate: this.value.taxRate,
        taxType: this.value.taxType,
    };

    @Watch("value")
    protected valueChanged() {
        this.modelValue.taxType = this.value.taxType;
    }

    protected updateModel() {
        this.value.taxType = this.modelValue.taxType;

        if (this.value.taxType === TaxType.FlatPercentage) {
            if (this.modelValue.taxRate === 0) {
                this.value.taxRate = null;
            } else {
                this.value.taxRate = this.modelValue.taxRate;
            }
        } else {
            this.value.taxRate = null;
        }

        this.$emit("input", {
            taxType: this.modelValue.taxType,
            taxRate: this.modelValue.taxRate,
        });
    }
}
