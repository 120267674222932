



















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CDeleteDialog extends Vue {
    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: object;
}
