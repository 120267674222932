



























import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CToolbarBtn extends Vue {
    @Prop({
        type: String,
    })
    public href!: string;

    @Prop({
        type: Boolean,
    })
    public outlined!: boolean;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: String,
    })
    public icon!: string;

    @Prop({
        type: String,
    })
    public label!: string;

    @Prop({
        type: Boolean,
    })
    public loading!: boolean;

    @Prop({
        type: String,
    })
    public tooltip!: string;

    @Prop({
        type: String,
    })
    public target!: string;

    public chipplyIcons = chipplyIcons;

    @Prop({
        type: String,
    })
    public color: string | undefined;

    protected computedColor() {
        if (this.color) {
            return this.color;
        }
        return "primary";
    }
}
