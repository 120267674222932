import IStoreFee from "@/chipply/event/IStoreFee";

export default class OrganizationStoreFee implements IStoreFee {
    public eventFeeTypeId = 0;
    public feeCost: number | null = null;
    public feeId = 0;
    public feeName = "";
    public processId = 0;
    public isActive = true;
    public inUseCannotDelete = true;
}
