


























import CAddressEditor from "@/components/ui/CAddressEditor.vue";
import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { IAddress } from "chipply-common";
import { Prop } from "vue-property-decorator";
@Component({
    components: {
        CAddressEditor,
        TextHeading,
    },
})
export default class OrderAddressEditor extends Vue {
    @Prop({
        type: Object,
    })
    public address!: IAddress | null;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: Boolean,
    })
    public enableAutocomplete!: boolean;

    @Prop({
        type: Boolean,
    })
    public isRequired!: boolean;

    @Prop({
        type: String,
    })
    public warning!: string;

    public chipplyIcons = chipplyIcons;
    protected validateAddressField(value: string) {
        if (!this.isRequired) {
            return true;
        }
        if (value) {
            return true;
        }
        return "You must enter a value";
    }
}
