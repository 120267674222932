























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CCopyButton extends Vue {
    @Prop({
        type: String,
    })
    public tooltip!: string | null;

    @Prop({
        type: Boolean,
    })
    public xSmall!: boolean;

    public showTooltip = false;

    @Prop({
        type: String,
    })
    public copyContent!: string;

    protected computedTooltip() {
        return this.tooltip || "Copy";
    }

    protected copy() {
        this.showTooltip = true;
        navigator.clipboard.writeText(this.copyContent);
        setTimeout(() => {
            this.showTooltip = false;
        }, 1000);
    }
}
