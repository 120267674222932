



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { EventType } from "@/chipply/event/EventType";
import { StoresMenuData } from "@/chipply/navigation/StoresMenuData";
import NavigationArea from "@/chipply/NavigationArea";
import NavigationSideButton from "@/components/navigation/NavigationSideButton.vue";
import NavigationSideHeader from "@/components/navigation/NavigationSideHeader.vue";
import { AsyncInteractionViewModel, Utils } from "chipply-common";
import { UrlUtils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import * as environmentStore from "@/store/EnvironmentStore";
import * as userInfoStore from "@/store/UserInfoStore";
import EventAdder from "@/components/events/EventAdder.vue";
import Vuex from "vuex";
import * as sessionStore from "@/store/SessionStore";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import { EventBus } from "@/chipply/EventBus";
import { Prop, Watch } from "vue-property-decorator";
import UserLevel from "@/chipply/UserLevel";
import chipplyIcons from "@/chipply/ImportIcons";
import CInformationVariant from "@/components/ui/CInformationVariant.vue";
import BatchSettingsNavigationDialog from "@/components/store/batch/BatchSettingsNavigationDialog.vue";
import { BatchSettingsNavigationDialogViewModel } from "@/chipply/store/batch/BatchSettingsNavigationDialogViewModel";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import CatalogBatchNavigationSide from "@/components/navigation/CatalogBatchNavigationSide.vue";
type StoreDetailMenuData = any;
type CatalogBatchDetailsMenuData = any;

Vue.use(Vuex);

@Component({
    computed: {
        StoresMenuData() {
            return StoresMenuData;
        },
    },
    components: {
        CatalogBatchNavigationSide,
        BatchSettingsNavigationDialog,
        NavigationSideHeader,
        NavigationSideButton,
        EventAdder,
        CInformationVariant,
    },
})
export default class NavigationSide extends Vue {
    public name = "NavigationSide";
    public previewPath!: string;
    public chipplyEventId!: number;
    public dealerId!: number;
    public salesRepId!: number;
    public expanded!: boolean;
    public isMoving!: boolean;

    @Prop({
        type: Number,
    })
    public navigationType!: number;

    @Prop({
        type: Object,
    })
    public menuData!: object | undefined;

    public location!: string;
    public showAddEventDialog!: boolean;
    public storeListReferralSettings!: IPageReferrerSettings;
    public stringStoreListState: string | undefined;
    public userLevel: UserLevel | undefined;
    public Utils = Utils;
    public EventType = EventType;
    public chipplyIcons = chipplyIcons;
    public NavigationArea = NavigationArea;
    public UserLevel = UserLevel;

    @Prop({
        type: Boolean,
    })
    public init!: boolean;

    public isPremium = false;
    public $refs!: {
        sideNav: HTMLElement;
        expandButton: HTMLElement;
        collapseButton: HTMLElement;
        sideMenuBlocker: HTMLElement;
    };

    @Prop({
        type: Boolean,
        default: false,
    })
    public isExpanderVisible!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isInDrawer!: boolean;

    public async mounted() {
        this.location = window.location.href;
        if (this.init) {
            await this.initialize();
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("storeliststate")) {
            this.storeListReferralSettings = JSON.parse(urlParams.get("storeliststate") as string);
            this.stringStoreListState = urlParams.get("storeliststate") as string;
        }
        this.populateFeedNames();
    }

    public async initialize() {
        await environmentStore.initialize(this.$store);
        await userInfoStore.initialize(this.$store);
        this.previewPath = environmentStore.getPreviewWebsitePath(this.$store);
        this.chipplyEventId = environmentStore.getChipplyEventId(this.$store);
        this.dealerId = userInfoStore.getDealerId(this.$store);
        this.salesRepId = userInfoStore.getUserId(this.$store);
        this.userLevel = userInfoStore.getUserLevel(this.$store);
        this.isPremium = userInfoStore.getIsPremium(this.$store);
    }

    @Watch("init")
    public async initChanged() {
        if (this.init) {
            await this.initialize();
        }
    }

    public data() {
        return {
            NavigationArea,
            UserLevel,
            chipplyEventId: undefined,
            dealerId: undefined,
            expanded: true,
            feedNames: [],
            location: "",
            previewPath: "",
            salesRepId: 0,
            showAddEventDialog: false,
            storeListReferralSettings: {},
            stringStoreListState: undefined,
            isMoving: false,
            userLevel: undefined,
        };
    }

    public get hasMissingColorAssignments() {
        var data: any = this.menuData;
        if (data && data.hasMissingColorAssignments) {
            return true;
        }
        return false;
    }

    public runFeed(feedName: string) {
        EventBus.$emit("run-feed-requested", feedName);
    }

    @Watch("menuData")
    protected menuDataChanged() {
        if ((this.menuData as any)?.preventNavigation) {
            this.$refs.sideMenuBlocker.style.display = "";
        }
    }

    public requestClose() {
        EventBus.$emit("navigation-side-close", this);
    }

    private populateFeedNames() {
        // TODO:  Add database schema for this data in the future
        this.$data.feedNames.push({ display: "A4", name: "A4" });
        this.$data.feedNames.push({ display: "Adidas", name: "Adidas" });
        this.$data.feedNames.push({ display: "Alpha Broder", name: "Alpha Broder" });
        this.$data.feedNames.push({ display: "Augusta", name: "Augusta" });
        // this.$data.feedNames.push("Badger");
        this.$data.feedNames.push({ display: "Champro", name: "Champro" });
        this.$data.feedNames.push({ display: "Charles River", name: "Charles River" });
        this.$data.feedNames.push({ display: "Cutter & Buck", name: "CutterBuck" });
        this.$data.feedNames.push({ display: "Delta", name: "Delta" });
        this.$data.feedNames.push({ display: "Founders", name: "Founders" });
        this.$data.feedNames.push({ display: "Mizuno", name: "Mizuno" });
        this.$data.feedNames.push({ display: "Pear Sox", name: "PearSox" });
        this.$data.feedNames.push({ display: "Pennant", name: "Pennant" });
        this.$data.feedNames.push({ display: "Rawlings", name: "Rawlings" });
        this.$data.feedNames.push({ display: "S&S Activewear", name: "S&S Activewear" });
        this.$data.feedNames.push({ display: "SanMar", name: "SanMar" });
        this.$data.feedNames.push({ display: "StadiumChair", name: "StadiumChair" });
        this.$data.feedNames.push({ display: "Under Armor", name: "Under Armor" });
        this.$data.feedNames.push({ display: "Vantage", name: "Vantage" });
        // this.$data.feedNames.push({display: "WAC", name: "WAC"});
        this.$data.feedNames.push({ display: "Wilson", name: "Wilson" });
    }

    private getVersionedLink(link: string) {
        return UrlUtils.getVersionedLink(link);
    }

    private expandCollapseSidebar() {
        if (window.innerWidth < 601 && this.expanded) {
            this.showHide();
        }
    }

    private getStoreDetailNavigationLink(storeLink: string, additionalParams?: string[]) {
        let baseLink = storeLink + "?eventid=" + (this.menuData as StoreDetailMenuData).eventId;
        baseLink += `&v=${Math.floor(Math.random() * 100000)}`;

        if (this.stringStoreListState) {
            baseLink += "&storeliststate=" + this.stringStoreListState;
        }

        additionalParams = additionalParams || [];
        for (const param of additionalParams) {
            baseLink += `&${param}`;
        }

        return baseLink;
    }

    private addNewClicked() {
        this.showAddEventDialog = true;
    }

    private hide() {
        if (!this.isExpanderVisible) {
            return;
        }
        this.$refs.sideNav.style.width = "0";
        this.$refs.sideMenuBlocker.style.width = "0";
        this.$refs.sideMenuBlocker.classList.add("collapsing");
        this.$refs.sideNav.classList.add("collapsing");
        setTimeout(() => {
            this.$refs.sideNav.style.display = "none";
            this.$refs.sideNav.classList.remove("collapsing");
            this.$refs.sideMenuBlocker.classList.remove("collapsing");
            this.isMoving = false;
        }, 1000);
        if (this.$refs.expandButton) {
            this.$refs.expandButton.style.display = "";
        }
        if (this.$refs.collapseButton) {
            this.$refs.collapseButton.style.display = "none";
        }
    }

    private show() {
        this.$refs.sideNav.style.display = "flex";
        this.$refs.sideNav.style.width = "230px";
        this.$refs.sideMenuBlocker.style.width = "230px";
        this.$refs.sideNav.classList.add("expanding");
        this.$refs.sideMenuBlocker.classList.add("expanding");
        setTimeout(() => {
            this.$refs.sideNav.classList.remove("expanding");
            this.$refs.sideMenuBlocker.classList.remove("expanding");
            this.isMoving = false;
        }, 1000);

        if (this.$refs.expandButton) {
            this.$refs.expandButton.style.display = "none";
        }
        if (this.$refs.collapseButton) {
            this.$refs.collapseButton.style.display = "";
        }
    }

    private showHide() {
        if (this.isMoving) {
            return;
        }
        if (this.expanded) {
            this.isMoving = true;
            this.hide();
        } else {
            this.isMoving = true;
            this.show();
        }
        this.expanded = !this.expanded;
    }
}
