






import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import ViewFactory from "@/ViewFactory";

@Component({})
export default class ViewContainer extends Vue {
    public isValid = false;

    @Prop({
        type: Object,
    })
    public vm!: object;

    public $refs!: {
        // Need to use any here as replaceChildren doesn't seem to compile on HtmlDivElement?
        container: any;
    };

    public async mounted() {
        this.createViewAsNeeded();
    }

    @Watch("vm")
    protected vmChanged(viewModel: object) {
        this.createViewAsNeeded();
    }

    protected createViewAsNeeded() {
        if (!this.vm) {
            return;
        }
        const instance = ViewFactory.getView(this.vm);
        instance.$mount();
        this.$refs.container.replaceChildren(instance.$el);
    }
}
