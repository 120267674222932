export default class ChamproValidation {
    public static ShippingCustomerAccount(shipMethod: string, customerAccount: string) {
        if (customerAccount === "" || customerAccount === null) {
            if (this.ShipMethodsNeedingACustomerAccount.includes(shipMethod)) {
                return "Customer Account is required for this Ship Method";
            } else {
                return true;
            }
        }

        if (this.ShipMethodsNotRequireACustomerAccount.includes(shipMethod)) {
            if (customerAccount !== "") {
                return "Customer Account is not required for this Ship Method";
            } else {
                return true;
            }
        }

        return true;
    }

    private static ShipMethodsNeedingACustomerAccount = [
        "PRIORITY OVERNIGHT THIRD PARTY",
        "PRIORITY OVERNIGHT COLLECT",
        "FEDEX 2 DAY THIRD PARTY",
        "FEDEX 2 DAY COLLECT",
        "FEDEX EXPRESS SAVER THIRD PARTY",
        "FEDEX EXPRESS SAVER COLLECT",
        "FEDEX GROUND THIRD PARTY",
        "GROUND HOME DELIVERY THIRD PARTY",
        "FEDEX GROUND COLLECT",
        "UPS NEXT DAY AIR THIRD PARTY",
        "UPS NEXT DAY AIR EARLY COLLECT",
        "UPS NEXT DAY AIR SAVER THIRD PARTY",
        "UPS NEXT DAY AIR SAVER COLLECT",
        "UPS NEXT DAY AIR COLLECT",
        "UPS 2ND DAY AIR THIRD PARTY",
        "UPS 2ND DAY AIR COLLECT",
        "UPS 3 DAY SELECT THIRD PARTY",
        "UPS 3 DAY SELECT COLLECT",
        "UPS GROUND THIRD PARTY",
        "UPS GROUND COLLECT",
    ];

    private static ShipMethodsNotRequireACustomerAccount = [
        "UPS GROUND",
        "FEDEX_GROUND",
        "UPS 3 DAY SELECT",
        "UPS 2ND DAY AIR A.M.",
        "UPS 2ND DAY AIR",
        "UPS NEXT DAY AIR SAVER",
        "UPS NEXT DAY AIR EARLY",
        "UPS NEXT DAY AIR",
        "FIRST_OVERNIGHT",
        "PRIORITY_OVERNIGHT",
        "STANDARD_OVERNIGHT",
        "FEDEX_2_DAY_AM",
        "FEDEX_2_DAY",
        "FEDEX_EXPRESS_SAVER",
        "GROUND_HOME_DELIVERY",
        "SMART_POST",
        "FEDEX 2 DAY ONE RATE",
        "FEDEX_FREIGHT_ECONOMY",
        "FEDEX_FREIGHT_PRIORITY",
        "CUSTOM CO",
    ];
}
