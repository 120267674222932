







import chipplyIcons from "@/chipply/ImportIcons";
import TextHeading from "@/components/utility/TextHeading.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        TextHeading,
    },
})
export default class ViewHeader extends Vue {
    public name = "ViewHeader";

    @Prop({
        type: String,
    })
    public title!: string;
}
