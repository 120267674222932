import { ListPageViewModel } from "../view-model/ListPageViewModel";
import { WebHelper } from "chipply-common";
import IVuetifyTableOptions from "../interface/IVuetifyTableOptions";
import FilterItemSimpleViewModel from "../view-model/FilterItemSimpleViewModel";
import PageState from "../PageState";
import { SimpleAsyncInteractionViewModel, AsyncInteractionWithDataViewModel } from "chipply-common";
import CreateShippingIntegrationOrdersArgs from "@/chipply/ecom-orders/CreateShippingIntegrationOrdersArgs";
import DeleteOrdersArgs from "@/chipply/ecom-orders/DeleteOrdersArgs";
import AnnotateAssessmentsArgs from "@/chipply/ecom-orders/AnnotateAssessmentsArgs";
import ShipStationSettings from "@/chipply/view-model/dealer/ShipStationSettings";
import IShippingStore from "@/chipply/dealer/IShippingStore";
import IEcomOrderResult from "@/chipply/ecom-orders/IEcomOrderResult";
import { IEcomOrderListArgs } from "../interface/IEcomOrderListArgs";
import EcomOrderListFilters from "@/chipply/ecom-orders/EcomOrderListFilters";
import { AssessmentAnnotation } from "./AssessmentAnnotation";
import { ITextValueDisabled } from "chipply-common";
import FilterItemDisplayViewModel from "../view-model/FilterItemDisplayViewModel";
import ITreeItem from "../interface/i-tree-item";
import ChargebackArgs from "./ChargebackArgs";
import chipplyIcons from "../ImportIcons";
import DealerHierarchyArgs from "../dealer/DealerHierarchyArgs";
import FilterItemMultiSelectViewModel from "../view-model/FilterItemMultiSelectViewModel";
import FilterItemSingleSelectViewModel from "../view-model/FilterItemSingleSelectViewModel";
import SortProperty from "../data-access/SortProperty";

export enum OptionDrawerType {
    FiltersEditor,
    FieldsSelector,
}

export default class OrderManagerViewModel extends ListPageViewModel {
    public items: IEcomOrderResult[] = [];
    public chipplyIcons = chipplyIcons;
    public dataTableExpandedItems: IEcomOrderResult[] = [];
    public filters: EcomOrderListFilters = new EcomOrderListFilters();
    public appliedFilters: FilterItemDisplayViewModel[] = [];
    public eventOptions: ITextValueDisabled<number>[] = [];
    public deletionStoreName!: string | undefined;
    public catalogBatchName = "";
    public isParent = false;
    public dealerOptions: ITreeItem[] = [];
    public organizations: ITextValueDisabled<number>[] = [];
    public dealers: ITextValueDisabled<number>[] = [];
    public events: object[] = [];
    public deletionStoreId!: number | undefined;
    public selectedStoreStatus = "scheduled";
    public wasSelectAllChecked = false;
    public isSelectingNextPage = false;
    public wasLastPageSelected = false;
    public showDeleteStoreDialog = false;
    public hasCreated = false;
    public isAdmin = false;
    public newParentSearch = false;
    public dataTableItemKey = "orderId";
    public dataTableShowSelect = false;
    public dataTableShowExpand = false;
    public dataTableLoading = false;
    public hideDefaultFooter = true;
    public dataTableDisableSort = false;
    public pageState: PageState;
    public serverItemsLength: number | null = null;
    private _pagination: IVuetifyTableOptions = {
        sortDesc: [true],
        sortBy: ["orderDate"],
        page: 1,
        itemsPerPage: 20,
    };
    public totalStores = 0;
    public orderIds: number[] = [];
    public isShippingIntegrationAvailable = false;
    public loading = false;
    public deleteDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public showPrintSalesOrderWarning = false;
    public showChargebackDialog = false;
    public showSendToShippingWarning = false;
    public showSuccessMessage = false;
    public showShipStationStoreSelection = false;
    public selectedShipStationId: number | null = null;
    public shippingStores: IShippingStore[] = [];
    public longRunningMessage: string | null = null;
    public optionsDrawerType: OptionDrawerType | null = null;
    public isOptionsDrawerVisible = false;
    public optionsDrawerViewModel = new AsyncInteractionWithDataViewModel<OptionDrawerType>();
    public isSecondaryOptionsDrawerVisible = false;
    public currentOptionsComponent: OptionDrawerType = OptionDrawerType.FiltersEditor;
    public panel = [0];
    public temporaryHeaders: any[] = [];

    public initialHeaders = [
        { text: "", value: "selected", sortable: false, width: "30px", visible: true },
        { text: "Order #", value: "orderId", width: "100px", visible: true, sortable: true },
        { text: "Sale #", value: "saleOrder", width: "90px", visible: true, sortable: true },
        {
            text: this.isAdmin ? "Order Status / Annotation" : "Order Status",
            value: "orderStatus",
            width: "140px",
            visible: true,
            sortable: true,
        },
        { text: "Store Status", value: "storeStatus", visible: true, width: "130px", sortable: true },
        { text: "Name", value: "billingName", visible: true, sortable: true },
        { text: "Email", value: "billingEmail", visible: true, sortable: true },
        { text: "Phone", value: "billingPhone", visible: true, width: "140px", sortable: true },
        { text: "Organization", value: "organizationName", visible: false, sortable: true },
        { text: "Company Name", value: "companyName", visible: false, sortable: true },
        { text: "Store Name", value: "storeName", visible: true, sortable: true },
        { text: "Store Type", value: "storeType", visible: true, sortable: true },
        { text: "Order Date", value: "orderDate", visible: true, sortable: true },
        { text: "Est. Ship Date", value: "estimatedShipDate", visible: true, sortable: true },
        { text: "Items", value: "items", visible: true, sortable: true },
        { text: "Total", value: "orderTotal", visible: true, sortable: true },
        { text: "Export To Shipping", value: "shippingExportDate", visible: true, sortable: true },
    ];

    private _allHeaders: any[] = [
        { text: "", value: "selected", sortable: false, width: "30px", visible: true },
        { text: "Order #", value: "orderId", width: "100px", visible: true, sortable: true },
        { text: "Sale #", value: "saleOrder", width: "90px", visible: true, sortable: true },
        {
            text: this.isAdmin ? "Order Status / Annotation" : "Order Status",
            value: "orderStatus",
            width: "140px",
            visible: true,
            sortable: true,
        },
        { text: "Store Status", value: "storeStatus", visible: true, width: "130px", sortable: true },
        { text: "Name", value: "billingName", visible: true, sortable: true },
        { text: "Email", value: "billingEmail", visible: true, sortable: true },
        { text: "Phone", value: "billingPhone", visible: true, width: "140px", sortable: true },
        { text: "Organization", value: "organizationName", visible: false, sortable: true },
        { text: "Company Name", value: "companyName", visible: false, sortable: true },
        { text: "Store Name", value: "storeName", visible: true, sortable: true },
        { text: "Store Type", value: "storeType", visible: true, sortable: true },
        { text: "Order Date", value: "orderDate", visible: true, sortable: true },
        { text: "Est. Ship Date", value: "estimatedShipDate", visible: true, sortable: true },
        { text: "Items", value: "items", visible: true, sortable: true },
        { text: "Total", value: "orderTotal", visible: true, sortable: true },
        { text: "Export To Shipping", value: "shippingExportDate", visible: true, sortable: true },
    ];

    public get allHeaders() {
        return this._allHeaders;
    }

    public set allHeaders(headers: any[]) {
        this._allHeaders = headers;
    }

    public get dealerHeader() {
        return {
            text: "Dealer",
            value: "dealer",
            visible: true,
            sortable: true,
        };
    }

    public actions = [
        {
            color: "primary",
            icon: "$iconReportsBullet",
            label: "Sales Orders",
            tooltip: "View Sales Orders",
            clickHandler: this.viewSalesOrder.bind(this),
        },
        {
            color: "primary",
            icon: "$iconPackage",
            label: "Ship Station",
            tooltip: "Send To Shipstation",
            clickHandler: this.sendToShipping.bind(this),
        },
    ];

    public adminActions = [
        {
            color: "primary",
            icon: "$iconReportsBullet",
            label: "Sales Orders",
            tooltip: "View Sales Orders",
            clickHandler: this.viewSalesOrder.bind(this),
        },
        {
            color: "primary",
            icon: "$iconPackage",
            label: "Shipstation",
            tooltip: "Send To Shipstation",
            clickHandler: this.sendToShipping.bind(this),
        },
        {
            color: "primary",
            icon: "$iconPurchasing",
            label: "Chargeback",
            tooltip: "Mark as Chargeback",
            clickHandler: this.markAsChargeback.bind(this),
        },
        {
            color: "primary",
            icon: "$iconHelpFill",
            label: "Mark As Fraud",
            tooltip: "Mark as Fraud",
            clickHandler: this.markAsFraudulent.bind(this),
        },
        {
            color: "primary",
            icon: "$iconCheckmarkSharp",
            label: "Mark As Legitimate",
            tooltip: "Mark as Legitimate",
            clickHandler: this.markAsLegitimate.bind(this),
        },
        {
            color: "error",
            icon: "$iconTrash",
            label: "Delete",
            tooltip: "Delete Order(s)",
            clickHandler: this.deleteOrders.bind(this),
        },
    ];

    public orderStatuses = [
        { text: "Paid", value: "paid" },
        { text: "Voided", value: "voided" },
        { text: "Failed", value: "failed" },
        { text: "Abandoned", value: "abandoned" },
        { text: "Refund Later", value: "refundlater" },
        { text: "Pay Later", value: "paylater" },
        { text: "Fraud", value: "fraud" },
    ];

    public shippingTypes = [
        { text: "Shipping", value: "Shipping" },
        { text: "Organization Delivery", value: "Organization Delivery" },
        { text: "In Store Pickup", value: "In Store Pickup" },
    ];

    public storeStatuses = [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Backordered", value: "backordered" },
        { text: "Production", value: "production" },
        { text: "Sorting", value: "sorting" },
        { text: "Complete", value: "complete" },
    ];

    public constructor() {
        super();
        this.pageState = new PageState(this.pagination);
        this.initializeHeaders();
        this.temporaryHeaders = this.allHeaders.map((header) => ({ ...header }));
        this.openEcomOrdersFilterEditor = this.openEcomOrdersFilterEditor.bind(this);
        this.openEcomOrdersFieldsSelector = this.openEcomOrdersFieldsSelector.bind(this);
        this.filtersViewModel.filterItems["billEmail"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["billFirstName"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["billLastName"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["billPhone"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["dealerIds"] = new FilterItemMultiSelectViewModel();
        this.filtersViewModel.filterItems["dealerIds"].selected = [];
        this.filtersViewModel.filterItems["orderId"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["ordersFromDate"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["ordersToDate"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["estimatedShipDate"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["orderStatus"] = new FilterItemSingleSelectViewModel();
        this.filtersViewModel.filterItems["storeStatus"] = new FilterItemSingleSelectViewModel();
        this.filtersViewModel.filterItems["organizationId"] = new FilterItemSingleSelectViewModel();
        this.filtersViewModel.filterItems["saleOrder"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["shippingType"] = new FilterItemSingleSelectViewModel();
        this.filtersViewModel.filterItems["eventId"] = new FilterItemSingleSelectViewModel();
        this.filtersViewModel.filterItems["catalogBatchId"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["postalCode"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["orderTotal"] = new FilterItemSimpleViewModel();
        this.filtersViewModel.filterItems["additionalInfoText"] = new FilterItemSimpleViewModel();
    }

    public get pagination(): IVuetifyTableOptions {
        return this._pagination;
    }

    public set pagination(value: IVuetifyTableOptions) {
        if (!this.isPaginationEquals(this._pagination, value)) {
            this._pagination = value;
            this.updateSortAndPageState();
        }
    }

    public get sortProperty(): SortProperty | null {
        if (this._pagination.sortBy.length > 0) {
            const sortBy = this._pagination.sortBy[0];
            const sortDesc = this._pagination.sortDesc[0];
            return new SortProperty(sortBy, sortDesc);
        }
        return null;
    }

    public get isSelectAllActive(): boolean {
        return this.wasSelectAllChecked || (this.items.length > 0 && this.items.every((item) => item.selected));
    }

    public updateSelection(item: IEcomOrderResult): void {
        item.selected = !item.selected;
    }

    public get hasSelectedItems(): boolean {
        return this.items.some((item) => item.selected);
    }

    public get dataTableHeaders() {
        return this.allHeaders.filter((header) => header.visible);
    }

    public initializeHeaders() {
        this._allHeaders = [
            { text: "", value: "selected", sortable: false, width: "30px", visible: true },
            { text: "Order #", value: "orderId", width: "100px", visible: true, sortable: true },
            { text: "Sale #", value: "saleOrder", width: "90px", visible: true, sortable: true },
            {
                text: this.isAdmin ? "Order Status / Annotation" : "Order Status",
                value: "orderStatus",
                width: "140px",
                visible: true,
                sortable: true,
            },
            { text: "Store Status", value: "storeStatus", visible: true, width: "130px", sortable: true },
            { text: "Name", value: "billingName", visible: true, sortable: true },
            { text: "Email", value: "billingEmail", visible: true, sortable: true },
            { text: "Phone", value: "billingPhone", visible: true, width: "140px", sortable: true },
            { text: "Organization", value: "organizationName", visible: false, sortable: true },
            { text: "Company Name", value: "companyName", visible: false, sortable: true },
            { text: "Store Name", value: "storeName", visible: true, sortable: true },
            { text: "Store Type", value: "storeType", visible: true, sortable: true },
            { text: "Order Date", value: "orderDate", visible: true, sortable: true },
            { text: "Est. Ship Date", value: "estimatedShipDate", visible: true, sortable: true },
            { text: "Items", value: "items", visible: true, sortable: true },
            { text: "Total", value: "orderTotal", visible: true, sortable: true },
            { text: "Export To Shipping", value: "shippingExportDate", visible: true, sortable: true },
        ];
    }

    public updateHeadersBasedOnRole() {
        if (this.isAdmin || this.isParent) {
            this._allHeaders.splice(8, 0, this.dealerHeader);
        }
        this.allHeaders = this._allHeaders;
        this.temporaryHeaders = this.allHeaders.map((header) => ({ ...header }));
    }

    public updateSortAndPageState(): void {
        this.pageState = new PageState(this.pagination);
    }

    public async list(isNewSearch: boolean): Promise<void> {
        this.newParentSearch = false;
        if (isNewSearch) {
            this.items = [];
            this.pageState.reset();
            this.filters.billEmail = this.filtersViewModel.filterItems.billEmail.getFilterValue<string>();
            this.filters.billFirstName = this.filtersViewModel.filterItems.billFirstName.getFilterValue<string>();
            this.filters.billLastName = this.filtersViewModel.filterItems.billLastName.getFilterValue<string>();
            this.filters.billPhone = this.removePhoneFormatting(
                this.filtersViewModel.filterItems.billPhone.getFilterValue<string>()
            );
            this.filters.dealerIds =
                this.filtersViewModel.filterItems.dealerIds.getFilterValue<ITextValueDisabled<number>[]>();
            this.filters.orderId = this.filtersViewModel.filterItems.orderId.getFilterValue<number>();
            this.filters.ordersFromDate = this.filtersViewModel.filterItems.ordersFromDate.getFilterValue<string>();
            this.filters.ordersToDate = this.filtersViewModel.filterItems.ordersToDate.getFilterValue<string>();
            this.filters.estimatedShipDate =
                this.filtersViewModel.filterItems.estimatedShipDate.getFilterValue<string>();
            this.filters.orderStatus = this.filtersViewModel.filterItems.orderStatus.getFilterValue<string>();
            this.filters.organizationId = this.filtersViewModel.filterItems.organizationId.getFilterValue<number>();

            if (this.filtersViewModel.filterItems.saleOrder.value === null) {
                this.filters.saleOrder = "";
            } else {
                this.filters.saleOrder = this.filtersViewModel.filterItems.saleOrder.value;
            }

            this.filters.eventId = this.filtersViewModel.filterItems.eventId.getFilterValue<number>();
            this.filters.storeStatus = this.filtersViewModel.filterItems.storeStatus.getFilterValue<string>();
            this.filters.shippingType = this.filtersViewModel.filterItems.shippingType.getFilterValue<string>();
            this.filters.postalCode = this.filtersViewModel.filterItems.postalCode.getFilterValue<string>();
            this.filters.orderTotal = this.filtersViewModel.filterItems.orderTotal.getFilterValue<number>();
            this.filters.additionalInfoText =
                this.filtersViewModel.filterItems.additionalInfoText.getFilterValue<string>();
        }

        if (this.pageState.wasLastPageSelected === true) {
            return;
        }

        const existingSelectedItems = this.items.filter((item) => item.selected);

        const unserializedData = await this.selectData();
        if (isNewSearch) {
            this.filtersViewModel.updateAppliedFilters();
        }
        this.organizations = unserializedData.organizations.filter((org: ITextValueDisabled<number>) => org.text != "");
        const shouldSelectNewItems = this.isSelectAllIntended();

        if (isNewSearch) {
            this.items = [];
            this.orderIds = [];
            this.items = unserializedData.orders.map((order: IEcomOrderResult) => ({
                ...order,
                selected: shouldSelectNewItems,
            }));
            for (const order of this.items) {
                this.orderIds.push(order.orderId);
            }
        } else {
            const newOrders = unserializedData.orders
                .filter((order: IEcomOrderResult) => !this.orderIds.includes(order.orderId))
                .map((order: IEcomOrderResult) => ({
                    ...order,
                    selected: shouldSelectNewItems,
                }));

            this.items.push(...newOrders);
            for (const order of newOrders) {
                this.orderIds.push(order.orderId);
            }
        }

        this.items.forEach((item) => {
            if (existingSelectedItems.some((selectedItem) => selectedItem.orderId === item.orderId)) {
                item.selected = true;
            }
        });

        this.events = unserializedData.events;
        this.dealers = unserializedData.dealers;
        this.newParentSearch = false;
        this.totalStores = unserializedData.totalCount;
        this.catalogBatchName = unserializedData.catalogBatchName;
        this.populateEventOptions();
    }

    public async selectData(): Promise<any> {
        if (!this.hasCreated) {
            return;
        }

        try {
            this.loading = true;
            if (!this.isAdmin) {
                this.newParentSearch = this.isParent;
            }
            const urlParams = new URLSearchParams(window.location.search);
            let pageUrl = "";
            if (this.isAdmin) {
                pageUrl = "/ng/admin-ecom-orders.html";
            } else {
                pageUrl = "/ng/ecom-orders.html";
            }

            if (urlParams.has("errorMessage")) {
                pageUrl += "?errorMessage=" + encodeURIComponent(urlParams.get("errorMessage") as string);
            }

            history.replaceState(this.getPageState(), "", this.getPageStateUrl(pageUrl));
            const data = await WebHelper.postJsonData("/api/EcomOrder/GetOrders", this.buildArgs());
            const unserializedData = JSON.parse(data);
            this.pageState.next(unserializedData.orders.length);
            this.isShippingIntegrationAvailable = unserializedData.isShippingIntegrationAvailable;
            return unserializedData;
        } finally {
            this.loading = false;
        }
    }

    public async fetchDealerData() {
        const args = new DealerHierarchyArgs();
        args.isAdmin = this.isAdmin;
        try {
            const data = await WebHelper.postJsonData("/api/dealer/listdealershierarchical", args);
            const dealerData = JSON.parse(data).dealers;
            this.isParent = dealerData[0].isParent;
            this.dealerOptions = this.normalizeDealerOptions(dealerData);

            if (this.isParent && !this.isAdmin) {
                const parentDealerId = dealerData[0].dealerId;
                this.filters.dealerIds = [
                    {
                        text: String(parentDealerId),
                        value: parentDealerId,
                    },
                ];
                this.filtersViewModel.filterItems.dealerIds.selected = this.filters.dealerIds;
            }
        } catch (error) {
            console.error("Error fetching dealer data:", error);
        }
    }

    private normalizeDealerOptions(dealers: any[]): ITreeItem[] {
        dealers.sort((a, b) => a.dealerName.localeCompare(b.dealerName));
        return dealers.map((dealer) => ({
            id: dealer.dealerId,
            name: dealer.dealerName,
            children: dealer.children && dealer.children.length > 0 ? this.normalizeDealerOptions(dealer.children) : [],
        }));
    }

    public async selectNextPage(): Promise<IEcomOrderResult[]> {
        this.isSelectingNextPage = true;
        try {
            const unserializedData = await this.selectData();
            const addedOrders: IEcomOrderResult[] = [];
            const shouldSelectNewItems = this.isSelectAllIntended();
            if (unserializedData.orders.length > 0) {
                for (const order of unserializedData.orders) {
                    if (this.orderIds.indexOf(order.orderId) < 0) {
                        this.items.push(order);
                        this.orderIds.push(order.orderId);
                        addedOrders.push(order);
                        if (shouldSelectNewItems) {
                            order.selected = true;
                        }
                    }
                }
                return addedOrders;
            }
            return [];
        } finally {
            this.isSelectingNextPage = false;
        }
    }

    public async deleteOrders(): Promise<void> {
        const selectedItems = this.items.filter((item) => item.selected);
        const deleteDialog = new SimpleAsyncInteractionViewModel();
        this.deleteDialogViewModel = deleteDialog;
        const result = await this.deleteDialogViewModel.interact();

        if (result === "cancel") {
            this.deleteDialogViewModel = null;
            return;
        }
        this.deleteDialogViewModel = null;
        this.loading = true;
        const selectedOrderIds = this.getSelectedOrderIds(selectedItems);
        const deleteArgs = new DeleteOrdersArgs();
        deleteArgs.orderIds = selectedOrderIds;
        try {
            await WebHelper.postJsonData("/api/EcomOrder/DeleteOrders", deleteArgs);
            await this.list(true);
        } finally {
            this.setSuccessMessage(selectedItems.length);
            this.showSuccessMessage = true;
            this.loading = false;
        }
    }

    public async sendShippingInformation(): Promise<void> {
        const selectedItems = this.items.filter((item) => item.selected);
        const incompleteStatuses = ["Voided", "Failed", "Abandoned"];
        let hasIncompleteOrder = false;
        for (const item of selectedItems) {
            if (incompleteStatuses.indexOf(item.orderStatus) > -1) {
                hasIncompleteOrder = true;
                break;
            }
        }
        if (hasIncompleteOrder) {
            this.showSendToShippingWarning = true;
        } else {
            await this.selectShipStationStore();
        }
    }

    public async selectShipStationStore() {
        this.showSendToShippingWarning = false;
        let isStoreSelectionEnabled = false;
        try {
            this.loading = true;
            const data: ShipStationSettings = await WebHelper.getJsonData(`/api/ShipStation/GetShipStationSettings`);
            isStoreSelectionEnabled = data.isStoreSelectionEnabled;
            if (isStoreSelectionEnabled) {
                this.selectedShipStationId = data.defaultShipStationStoreId;
            }
        } finally {
            this.loading = false;
        }
        if (isStoreSelectionEnabled) {
            try {
                this.shippingStores = [];
                this.loading = true;
                const data: IShippingStore[] = await WebHelper.getJsonData(`/api/ShipStation/GetShippingStores`);
                this.shippingStores = data;
            } finally {
                this.loading = false;
            }
            if (this.shippingStores.length > 0) {
                this.showShipStationStoreSelection = true;
                return;
            }
        }
        await this.sendToShippingConfirm();
    }

    public confirmShippingStore() {
        this.showShipStationStoreSelection = false;
        this.sendToShippingConfirm();
    }

    public async sendToShippingConfirm(): Promise<void> {
        const selectedItems = this.items.filter((item) => item.selected);
        const selectedOrderIds = this.getSelectedOrderIds(selectedItems);
        const baseUrl = "/api/EcomOrder/SendShippingInformation";
        try {
            this.loading = true;
            const args = new CreateShippingIntegrationOrdersArgs();
            args.filters = this.filters;
            args.shippingStoreId = this.selectedShipStationId;
            if (this.isSelectAllIntended()) {
                args.orderIds = [];
                args.shouldSelectAll = true;
            } else {
                args.orderIds = selectedOrderIds;
            }
            const resultsText = await WebHelper.postJsonData(baseUrl, args);
            const results = JSON.parse(resultsText);
            await this.list(true);
        } catch {
            // TODO:
        } finally {
            this.loading = false;
            this.selectedShipStationId = null;
        }
    }

    public async markAsFraudulent(): Promise<void> {
        await this.annotateAssessments("FRAUDULENT");
        await this.list(false);
    }

    public async markAsLegitimate(): Promise<void> {
        await this.annotateAssessments("LEGITIMATE");
        await this.list(false);
    }

    public async annotateAssessments(annotation: AssessmentAnnotation): Promise<void> {
        const selectedItems = this.items.filter((item) => item.selected);
        const args = new AnnotateAssessmentsArgs();
        args.annotateAssessmentArgs.annotation = annotation;
        args.filters = this.filters;
        this.loading = true;
        if (this.isSelectAllIntended()) {
            args.shouldSelectAll = true;
        } else {
            const selectedOrderIds = this.getSelectedOrderIds(selectedItems);
            args.orderIds = selectedOrderIds;
        }
        const data = await WebHelper.postJsonData("/api/EcomOrder/AnnotateAssessments", args);
        this.loading = false;
        this.setSuccessMessage(selectedItems.length);
        this.showSuccessMessage = true;
    }

    public printSalesOrders(): void {
        const selectedItems = this.items.filter((item) => item.selected);
        const url = this.getPrintUrl(selectedItems);

        const incompleteStatuses = ["Voided", "Failed", "Abandoned"];
        let hasIncompleteOrder = false;
        for (const item of selectedItems) {
            if (incompleteStatuses.indexOf(item.orderStatus) > -1) {
                hasIncompleteOrder = true;
                break;
            }
        }

        if (hasIncompleteOrder) {
            this.showPrintSalesOrderWarning = true;
        } else {
            this.printSalesOrdersConfirm();
        }
    }

    public async printSalesOrdersConfirm() {
        const selectedItems = this.items.filter((item) => item.selected);
        if (selectedItems.length === 0) {
            return;
        }

        const url = this.getPrintUrl(selectedItems);
        if (url.length < 1900) {
            location.assign(url);
        } else {
            this.loading = true;
            const orderIds = this.getSelectedOrderIds(selectedItems);
            this.longRunningMessage = "Retrieving Sales Orders...";
            const attachment = await WebHelper.postJsonDataBlob("/api/Reports/SaleOrder", { orderIds });
            WebHelper.downloadAsAttachment(attachment, "SalesOrder.pdf");
            this.longRunningMessage = null;
            this.loading = false;
        }
    }

    public buildArgs(): IEcomOrderListArgs {
        return {
            filters: this.filters,
            page: this.pageState.page,
            pageSize: this.pageState.itemsPerPage,
            isAdmin: this.isAdmin,
            newParentSearch: this.newParentSearch,
        };
    }

    public getSelectedOrderIds(selectedItems: IEcomOrderResult[]): number[] {
        return selectedItems.map((item) => item.orderId);
    }

    public getPrintUrl(selectedItems: IEcomOrderResult[]): string {
        if (this.isSelectAllIntended()) {
            const url = new URL("ng/sales-order.html", window.location.origin);
            url.searchParams.append("shouldselectall", "true");
            if (this.filters.billEmail) {
                url.searchParams.append("billemail", this.filters.billEmail);
            }
            if (this.filters.billFirstName) {
                url.searchParams.append("billfirstname", this.filters.billFirstName);
            }
            if (this.filters.billLastName) {
                url.searchParams.append("billlastname", this.filters.billLastName);
            }
            if (this.filters.billPhone) {
                url.searchParams.append("billphone", this.filters.billPhone);
            }
            if (this.filters.ordersFromDate) {
                url.searchParams.append("ordersfromdate", this.filters.ordersFromDate);
            }
            if (this.filters.ordersToDate) {
                url.searchParams.append("orderstodate", this.filters.ordersToDate);
            }
            if (this.filters.orderStatus) {
                url.searchParams.append("orderstatus", this.filters.orderStatus);
            }
            if (this.filters.organizationId) {
                url.searchParams.append("organizationid", this.filters.organizationId.toString());
            }
            if (this.filters.saleOrder) {
                url.searchParams.append("saleorder", this.filters.saleOrder);
            }
            if (this.filters.shippingType) {
                url.searchParams.append("shippingtype", this.filters.shippingType);
            }
            if (this.filters.eventId) {
                url.searchParams.append("eventid", this.filters.eventId.toString());
            }
            if (this.filters.catalogBatchId) {
                url.searchParams.append("batchid", this.filters.catalogBatchId.toString());
            }
            return url.toString();
        }
        const orderIds = this.getSelectedOrderIds(selectedItems);
        const orderIdsQueryString = JSON.stringify(orderIds);
        return "/ng/sales-order.html?orderids=" + orderIdsQueryString;
    }

    public selectAll(args: { items: any[]; value: boolean }): void {
        if (args.value === true) {
            this.wasSelectAllChecked = true;
        } else {
            this.wasSelectAllChecked = false;
        }
    }

    public isSelectAllIntended() {
        return this.wasSelectAllChecked || this.isSelectAllActive;
    }

    public checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight &&
            !this.wasLastPageSelected
        ) {
            this.selectNextPage();
        }
    }

    public async deleteEvent(accepted: boolean) {
        this.showDeleteStoreDialog = false;
        const eventId = this.deletionStoreId;
        this.deletionStoreId = undefined;
        this.deletionStoreName = undefined;

        if (!accepted) {
            return;
        }

        this.loading = true;
        await WebHelper.delete("/api/Events/" + eventId);
        await this.list(true);
        this.loading = false;
    }

    public displayDeleteEventDialog(eventId: number, eventName: string) {
        this.showDeleteStoreDialog = true;
        this.deletionStoreId = eventId;
        this.deletionStoreName = eventName;
    }

    public clearSelections(): void {
        this.items.forEach((item) => (item.selected = false));
    }

    public getPageState() {
        return {
            description: "Order Manager",
            filters: this.filters,
            pagination: this.pagination,
            url: location.pathname + "?readstate=1",
        };
    }

    public getPageStateUrl(pageUrl: string) {
        const serializedValue = JSON.stringify(this.getPageState());

        if (pageUrl.indexOf("?") === -1) {
            return pageUrl + "?state=" + encodeURIComponent(serializedValue);
        }
        return pageUrl + "&state=" + encodeURIComponent(serializedValue) + "&v=" + Math.floor(Math.random() * 100000);
    }

    private populateEventOptions() {
        this.eventOptions = this.items.map((item: any) => ({
            text: item.storeName,
            value: item.eventId,
            disabled: false,
        }));
    }

    public async openEcomOrdersFilterEditor() {
        this.cleanOptionsDrawer(OptionDrawerType.FiltersEditor);
        this.showOptionsDrawer(OptionDrawerType.FiltersEditor);
    }

    public async openEcomOrdersFieldsSelector() {
        this.cleanOptionsDrawer(OptionDrawerType.FieldsSelector);
        this.showOptionsDrawer(OptionDrawerType.FieldsSelector);
    }

    private cleanOptionsDrawer(drawerType: OptionDrawerType) {
        if (this.optionsDrawerType && this.optionsDrawerType !== drawerType) {
            this.optionsDrawerType = null;
        }
    }

    private showOptionsDrawer(drawerType: OptionDrawerType) {
        this.optionsDrawerType = drawerType;
        this.isOptionsDrawerVisible = true;
    }

    public clearAllFilters() {
        this.filters = new EcomOrderListFilters();
        this.filtersViewModel.appliedFilters = [];
    }

    public applyFilters(filters: FilterItemDisplayViewModel[]) {
        this.filtersViewModel.appliedFilters = filters;
    }

    public resetFilters() {
        this.filtersViewModel.clearAll();
        this.list(true);
    }

    public closeOptionsDrawer() {
        this.isOptionsDrawerVisible = false;
        this.optionsDrawerType = null;
    }

    public toggleItemSelection(item: IEcomOrderResult): void {
        item.selected = !item.selected;
    }

    public getOrderStatusColor(status: string | null): string {
        if (!status) return "grey";
        switch (status.toLowerCase()) {
            case "paid":
                return "green";
            case "voided":
                return "red";
            case "failed":
                return "red";
            case "abandoned":
                return "orange";
            case "refund later":
                return "yellow";
            case "pay later":
                return "blue";
            case "fraud":
                return "black";
            default:
                return "grey";
        }
    }

    public getStoreStatusColor(status: string): string {
        switch (status.toLowerCase()) {
            case "open":
                return "black";
            case "closed":
                return "green";
            case "scheduled":
                return "black";
            case "production":
                return "yellow";
            case "sorting":
                return "yellow";
            case "complete":
                return "red";
            case "backordered":
                return "yellow";
            default:
                return "grey";
        }
    }

    public async done(action: string) {
        if (action === "cancel") {
            this.showChargebackDialog = false;
        } else if (action === "accept") {
            const selectedItems = this.items.filter((item) => item.selected);
            const selectedOrderIds = this.getSelectedOrderIds(selectedItems);

            const chargebackArgs = new ChargebackArgs();
            chargebackArgs.orderIds = selectedOrderIds;
            chargebackArgs.isAdmin = this.isAdmin;

            try {
                this.loading = true;
                await WebHelper.postJsonData("/api/EcomOrder/MarkAsChargeback", chargebackArgs);
            } catch (error) {
                console.error("Error marking orders as chargeback:", error);
            } finally {
                this.list(true);
                this.loading = false;
                this.showChargebackDialog = false;
                this.setSuccessMessage(selectedItems.length);
                this.showSuccessMessage = true;
            }
        }
    }

    public setSuccessMessage(selectedItemsCount: number): void {
        const itemText = selectedItemsCount === 1 ? "item" : "items";
        this.successMessage = `${selectedItemsCount} ${itemText} updated`;
    }

    public markAsChargeback() {
        this.showChargebackDialog = true;
    }

    public sendToShipping() {
        this.sendShippingInformation();
    }

    public viewSalesOrder() {
        this.printSalesOrders();
    }

    public removePhoneFormatting(phoneNumber: string): string {
        if (!phoneNumber) {
            return "";
        }
        return phoneNumber.replace(/[^0-9]/g, "");
    }
}
