



















import { AccountingSummaryPageViewModel } from "@/chipply/event/AccountingSummaryPageViewModel";
import AccountingSummaryPage from "@/components/page/AccountingSummaryPage.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import AccountingSummaryViewModel from "@/chipply/event/AccountingSummaryViewModel";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import { Utils } from "chipply-common";
import HtmlTextField from "@/components/HtmlTextField.vue";
import CSaveButton from "@/components/ui/CSaveButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import EditLayout from "@/components/layout/EditLayout.vue";
import CTableBtn from "@/components/ui/CTableBtn.vue";
import ListLayout from "@/components/layout/ListLayout.vue";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import WorkOrderReportHeading from "@/components/reports/WorkOrderReportHeading.vue";
import CDate from "@/components/ui/CDate.vue";
import CAccountingSummary from "@/components/events/CAccountingSummary.vue";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";

@Component({
    computed: {
        chipplyIcons() {
            return chipplyIcons;
        },
        Utils() {
            return Utils;
        },
    },
    components: {
        CAccountingSummary,
        CDate,
        WorkOrderReportHeading,
        ListLayout,
        CTableBtn,
        EditLayout,
        LongRunningOperationDialog,
        CToolbarBtn,
        CEditButton,
        CSaveButton,
        HtmlTextField,
        CControlLabel,
    },
})
export default class AccountingSummary extends mixins(ScrollableContentMixin) {
    @Prop({ type: Object })
    public vm!: AccountingSummaryPageViewModel;
    public options = new AccountingSummaryToggleOptions();

    mounted() {
        window.addEventListener("keydown", this.handlePrintShortcut);
    }

    beforeDestroy() {
        window.removeEventListener("keydown", this.handlePrintShortcut);
    }

    handlePrintShortcut(event: KeyboardEvent) {
        // Detect Ctrl + P
        if ((event.ctrlKey || event.metaKey) && event.key === "p") {
            event.preventDefault(); // Prevent the default print dialog
            this.printPage(); // Trigger the custom print function
        }
    }

    public async created() {
        if (this.vm.storeId) {
            await this.vm.getStoreAccountingSummary();
        }
        if (this.vm.catalogBatchId) {
            await this.vm.getCatalogBatchAccountingSummary();
        }
    }

    printPage() {
        const content = this.$refs.content as HTMLElement;

        // Clone the content
        const clone = content.cloneNode(true) as HTMLElement;

        // Hide all content except for the clone
        const originalContent = document.body.children;
        Array.from(originalContent).forEach((element) => {
            if (element !== clone) {
                (element as HTMLElement).style.display = "none";
            }
        });

        // Add clone to the body for printing
        clone.style.position = "relative";
        document.body.appendChild(clone);

        // Modify styles of the cloned content
        this.beforePrint(clone);

        // Trigger print
        window.print();

        // Clean up after print
        document.body.removeChild(clone);
        Array.from(originalContent).forEach((element) => {
            (element as HTMLElement).style.display = ""; // Restore original content visibility
        });
    }

    beforePrint(clone: HTMLElement) {
        // Decrease all font sizes by 2px, excluding elements with the class "notes-header"
        const elements = clone.querySelectorAll("*");
        elements.forEach((element) => {
            if (!(element as HTMLElement).classList.contains("prt-screen-font-override")) {
                const computedStyle = window.getComputedStyle(element);
                const fontSize = parseFloat(computedStyle.fontSize);
                if (fontSize) {
                    (element as HTMLElement).style.fontSize = `${fontSize - 2}px`;
                }
            }
        });

        // Increase font size of elements with the class "notes-header" by 1px
        const notesHeaders = clone.querySelectorAll(".prt-screen-font-override");
        notesHeaders.forEach((header) => {
            const computedStyle = window.getComputedStyle(header);
            const fontSize = parseFloat(computedStyle.fontSize);

            if (fontSize) {
                (header as HTMLElement).style.fontSize = `${fontSize + 1}px`;
            }
        });

        // Decrease font size of all table headers by an additional 1px
        const tableHeaders = clone.querySelectorAll("th");
        tableHeaders.forEach((header) => {
            const computedStyle = window.getComputedStyle(header);
            const fontSize = parseFloat(computedStyle.fontSize);
            if (fontSize) {
                (header as HTMLElement).style.fontSize = `${fontSize - 3}px`;
            }
        });

        // Ensure all content is visible
        clone.style.height = "auto";
        clone.style.overflow = "visible";

        // Set the width of the cloned content to 100% and adjust margins
        clone.style.width = "100%";
        clone.style.margin = "0";
        clone.style.padding = "0";

        // Add additional styles to ensure full width usage
        const style = document.createElement("style");
        style.innerHTML = `
        @media print {
            body, html {
                width: 100%;
                margin: 0;
                padding: 0;
            }
            @page {
                size: auto;
                margin: 0;
            }
            .print-container {
                width: 100%;
                margin: 0;
                padding: 0;
                display: block;
            }
            .print-container * {
                max-width: 100%;
                width: auto;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }
            .print-container h1, .print-container h2, .print-container h3, .print-container h4, .print-container h5, .print-container h6 {
                width: auto;
                max-width: none;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }
        }
    `;

        clone.appendChild(style);
        clone.classList.add("print-container");

        // Ensure the parent elements also use full width
        let parent = clone.parentElement;
        while (parent) {
            parent.style.width = "100%";
            parent.style.margin = "0";
            parent.style.padding = "0";
            parent = parent.parentElement;
        }
    }
}
