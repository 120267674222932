
























































































































































import Vue from "vue";
import Component from "vue-class-component";
import IProductColorSizes from "@/chipply/products/IProductColorSizes";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class ProductColorSizeGrid extends Vue {
    public name = "ProductColorSizeGrid";
    public checkAll = false;

    @Prop({
        type: Object,
    })
    public settings!: IProductColorSizes;

    @Prop({
        type: Boolean,
    })
    public showCheckAll!: boolean;

    @Prop({
        type: String,
    })
    public contentCellStyle!: string;
    public internalContentCellStyle = this.contentCellStyle || "min-width: 80px;max-width: 80px;";

    public $refs!: {
        columnHeader: HTMLDivElement;
        rowHeader: HTMLDivElement;
        rowHeaderSelector: HTMLDivElement;
        tableContent: HTMLDivElement;
    };

    @Prop({
        type: Number,
        default: 300,
    })
    public tableHeight!: number;

    @Prop({
        type: Number,
        default: 650,
    })
    public tableWidth!: number;

    @Watch("contentCellStyle")
    protected onContentCellStyleChanged() {
        this.internalContentCellStyle = this.contentCellStyle;
    }

    protected checkAllChanged(checked: boolean) {
        this.$emit("check-all", checked);
    }

    protected checkAllColorsChanged(colorIndex: number, color: object) {
        this.$emit("check-all-color", { colorIndex, color });
    }

    protected checkAllSizesChanged(sizeIndex: number, size: object) {
        this.$emit("check-all-size", { sizeIndex, size });
    }

    private calculatePositions() {
        this.$refs.columnHeader.scrollLeft = this.$refs.tableContent.scrollLeft;
        this.$refs.rowHeader.scrollTop = this.$refs.tableContent.scrollTop;
        this.$refs.rowHeaderSelector.scrollTop = this.$refs.tableContent.scrollTop;
    }

    /**
     * add space between "/" to allow better word break
     * @param originalColorName
     */
    public adjustedColorName(originalColorName: string) {
        return originalColorName.split("/").join(" / ");
    }
}
