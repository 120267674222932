import CustomerStoreFeeViewModel from "@/chipply/event/CustomerStoreFeeViewModel";
import IStoreSettingsModel from "@/chipply/store/settings/IStoreSettingsModel";
import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { ITextValueDisabled } from "chipply-common";
import ITaxSetting from "@/chipply/ITaxSetting";
import OrganizationContactEditViewModel from "@/chipply/organization/OrganizationContactEditViewModel";
import { IStoreSettingsViewModel } from "@/chipply/store/settings/IStoreSettingsViewModel";
import { DateUtils, ViewModel } from "chipply-common";
import { DateTime } from "luxon";
import { CatalogStoreSettingsViewModel } from "@/chipply/store/settings/CatalogStoreSettingsViewModel";
import { EventStatusEnum } from "@/chipply/store/EventStatusEnum";

export default class StoreSettingsViewModel implements ViewModel, IStoreSettingsViewModel {
    public storeId = 0;

    public ordersFulfilledDaily = false;
    public storeNotes = "";
    public storeName = "";
    public salesOrder = "";
    public storeUrl = "";
    public storePassword = "";
    public createAutomatedEmails = false;
    public hideInDealerList = false;
    public allowPo = false;
    public hideCreditCardEntry = false;
    public hideBilling = false;
    public hideFundraisingOnOrgDashboard = false;
    public openDate = "";
    public openTime = "";
    public closeDate = "";
    public closeTime = "";
    public productionDueDate = "";
    public deliverDueDate = "";
    public lastBatchDate = "";
    public nextBatchDate = "";
    public forceBatch = false;
    public forceBatchMessage = "";
    public isLegacyCatalogBatch = false;
    public isBackordered = false;
    public hideStoreRequest = false;

    public orgBranchId = 0;
    public dealerStorePrefix = "";
    public salespersonId = 0;

    public organizationDirectorUrl = "";
    public organizationDirectorPassword = "";
    public storeReady = false;
    public showPassword = false;

    public productTax!: ITaxSetting;
    public handlingTax!: ITaxSetting;
    public shippingTax!: ITaxSetting;

    public customerFees: CustomerStoreFeeViewModel[] = [];
    public organizations: ITextValueDisabled<number>[] = [];
    public organizationFees: OrganizationStoreFeeViewModel[] = [];
    public organizationContacts: OrganizationContactEditViewModel[] = [];
    public shownOrganizationContacts: OrganizationContactEditViewModel[] = [];
    public organizationContactsExpanded = false;
    public statusText = "";
    public organizationName = "";

    public shouldDisplayDetailedHandlingFees = false;

    private _originalStatusId: number | null = null;
    protected _statusId: number | null = null;
    protected _orgId = 0;

    public get maxCloseDate() {
        const startDateTime = DateTime.fromISO(this.openDate);
        const monthAdjustment = startDateTime.plus({
            months: 15,
        });
        return monthAdjustment.toISODate();
    }

    public get orgId() {
        return this._orgId;
    }

    public set orgId(value) {
        this._orgId = value;
        for (const org of this.organizations) {
            if (org.value !== value) {
                continue;
            }
            this.organizationName = org.text;
            return;
        }
        this.organizationName = "";
    }

    public get statusId() {
        return this._statusId;
    }

    public set statusId(value: number | null) {
        switch (value) {
            case EventStatusEnum.Production:
                this.statusText = "Production";
                break;
            case EventStatusEnum.Sorting:
                this.statusText = "Sorting";
                break;
            case EventStatusEnum.Complete:
                this.statusText = "Complete";
                break;
            case EventStatusEnum.Backorder:
                this.statusText = "Backordered";
                break;
            default:
                this.statusText = "Closed (Ready To Process)";
                break;
        }
        this._statusId = value;
    }

    public get statusStyle(): string {
        switch (this.statusId) {
            case EventStatusEnum.Production:
            case EventStatusEnum.Sorting:
                return "background-color: yellow; color: #000000";
            case EventStatusEnum.Complete:
                return "background-color: red";
            default:
                return "background-color: green; ";
        }
    }

    public get showStoreStatus(): boolean {
        const closeDate = this.closeDate;
        const closeTime = this.closeTime;
        const storeReady = this.storeReady;
        if (!storeReady) {
            return false;
        }
        const dstCloseDate = DateUtils.getDstDate(closeDate, closeTime);
        if (DateTime.local() < DateTime.fromISO(dstCloseDate)) {
            return false;
        }
        return true;
    }

    public get openTimeInFuture(): boolean {
        const closeDate = this.openDate;
        const closeTime = this.openTime;
        const dstCloseDate = DateUtils.getDstDate(closeDate, closeTime);
        return DateTime.local() < DateTime.fromISO(dstCloseDate);
    }

    public constructor(args: StoreSettingsPageModel) {
        const data = args.store;
        if (!data) {
            return;
        }

        this.organizations = args.organizations;
        this.storeId = data.storeId;
        this.ordersFulfilledDaily = data.ordersFulfilledDaily;
        this.storeReady = data.storeReady;
        this.storeName = data.storeName;
        this.salesOrder = data.salesOrder;
        this.storeUrl = data.storeUrl;
        this.storePassword = data.storePassword;
        this.createAutomatedEmails = data.createAutomatedEmails;
        this.hideInDealerList = data.hideInDealerList;
        this.allowPo = data.allowPo;
        this.hideCreditCardEntry = data.hideCreditCardEntry;
        this.hideFundraisingOnOrgDashboard = data.hideFundraisingOnOrgDashboard;
        const openParse = DateUtils.parseDateString(data.openDate);
        this.openDate = openParse.dateString;
        this.openTime = openParse.timeString;
        const closeParse = DateUtils.parseDateString(data.closeDate);
        this.closeDate = closeParse.dateString;
        this.closeTime = closeParse.timeString;
        this.productionDueDate = DateUtils.parseDateString(data.productionDueDate).dateString;
        this.deliverDueDate = DateUtils.parseDateString(data.deliverDueDate).dateString;
        this.storeNotes = data.storeNotes;
        this.orgId = data.orgId;
        this.orgBranchId = data.orgBranchId;
        this.dealerStorePrefix = data.dealerStorePrefix;
        this.salespersonId = data.salespersonId;
        this.organizationDirectorUrl = data.organizationDirectorUrl;
        this.organizationDirectorPassword = data.organizationDirectorPassword;
        this.productTax = data.productTax;
        this.handlingTax = data.handlingTax;
        this.shippingTax = data.shippingTax;
        this.showPassword = data.showPassword;
        this.forceBatch = data.forceBatch;
        this.isLegacyCatalogBatch = data.isLegacyCatalogBatch;
        this.isBackordered = data.isBackordered;
        this.hideStoreRequest = data.hideStoreRequest;

        this.assignStatusId(data);

        if (this.forceBatch) {
            this.forceBatchMessage = "Scheduled To Batch";
        }

        const lastBatchDate = DateUtils.parseDateString(data.lastBatchDate, true);
        const isValidDateTimeString = lastBatchDate.dateString && lastBatchDate.timeString;
        this.lastBatchDate += isValidDateTimeString
            ? `Last Batch: ${lastBatchDate.dateString} ${lastBatchDate.timeString}`
            : "Last Batch: None";

        const nextBatchDate = DateUtils.parseDateString(data.nextBatchDate, true);
        const isValidNextBatchDateTimeString = nextBatchDate.dateString && nextBatchDate.timeString;
        this.nextBatchDate = isValidNextBatchDateTimeString
            ? `Next Batch: ${nextBatchDate.dateString} ${nextBatchDate.timeString}`
            : "";

        this.shouldDisplayDetailedHandlingFees = data.shouldDisplayDetailedHandlingFees;
        this.hideBilling = data.hideBilling;

        for (const contact of data.organizationContacts) {
            this.organizationContacts.push(new OrganizationContactEditViewModel(contact));
        }

        this.shownOrganizationContacts = this.organizationContacts.slice(0, 3);

        for (const eventFee of data.customerFees) {
            this.customerFees.push(new CustomerStoreFeeViewModel(eventFee, args.feeTypes));
        }

        for (const eventFee of data.organizationFees) {
            this.organizationFees.push(new OrganizationStoreFeeViewModel(eventFee, args.processes, args.feeTypes));
        }
    }

    private assignStatusId(data: IStoreSettingsModel) {
        const isLegacyBatchAndHasBackorders =
            this instanceof CatalogStoreSettingsViewModel && this.isLegacyCatalogBatch && this.isBackordered;
        const hasBackOrders = !(this instanceof CatalogStoreSettingsViewModel) && this.isBackordered;

        if (isLegacyBatchAndHasBackorders || hasBackOrders) {
            this.statusId = EventStatusEnum.Backorder;
            this._originalStatusId = data.statusId;
        } else {
            this.statusId = data.statusId;
        }
    }

    public async organizationChanged() {
        this.orgBranchId = 0;
        this.organizationContacts = [];
        this.shownOrganizationContacts = [];
    }

    public toggleOrganizationContacts() {
        if (this.organizationContactsExpanded) {
            this.shownOrganizationContacts = this.organizationContacts.slice(0, 3);
        } else {
            this.shownOrganizationContacts = this.organizationContacts;
        }
        this.organizationContactsExpanded = !this.organizationContactsExpanded;
    }

    public toModel(): IStoreSettingsModel {
        const model: IStoreSettingsModel = {
            $typeHint: "",
            allowPo: this.allowPo,
            closeDate: DateUtils.getDstDate(this.closeDate, this.closeTime),
            createAutomatedEmails: this.createAutomatedEmails,
            customerFees: [],
            dealerStorePrefix: this.dealerStorePrefix,
            deliverDueDate: this.deliverDueDate,
            handlingTax: this.handlingTax,
            hideCreditCardEntry: this.hideCreditCardEntry,
            hideFundraisingOnOrgDashboard: this.hideFundraisingOnOrgDashboard,
            hideInDealerList: this.hideInDealerList,
            nextBatchDate: this.nextBatchDate,
            openDate: DateUtils.getDstDate(this.openDate, this.openTime),
            ordersFulfilledDaily: this.ordersFulfilledDaily,
            orgBranchId: this.orgBranchId,
            orgId: this.orgId,
            organizationContacts: [],
            organizationDirectorPassword: this.organizationDirectorPassword,
            organizationDirectorUrl: this.organizationDirectorUrl,
            organizationFees: [],
            productTax: this.productTax,
            productionDueDate: this.productionDueDate,
            salesOrder: this.salesOrder,
            salespersonId: this.salespersonId,
            shippingTax: this.shippingTax,
            statusId: this.statusId === EventStatusEnum.Backorder ? this._originalStatusId : this.statusId,
            storeId: this.storeId,
            storeReady: this.storeReady,
            storeName: this.storeName,
            storeNotes: this.storeNotes,
            storePassword: this.storePassword,
            storeUrl: this.storeUrl,
            showPassword: this.showPassword,
            lastBatchDate: this.lastBatchDate,
            forceBatch: this.forceBatch,
            isLegacyCatalogBatch: this.isLegacyCatalogBatch,
            isBackordered: this.isBackordered,
            hideStoreRequest: this.hideStoreRequest,
            shouldDisplayDetailedHandlingFees: this.shouldDisplayDetailedHandlingFees,
            hideBilling: this.hideBilling,
        };

        for (const fee of this.customerFees) {
            model.customerFees.push(fee.toModel());
        }

        for (const fee of this.organizationFees) {
            model.organizationFees.push(fee.toModel());
        }

        for (const con of this.organizationContacts) {
            model.organizationContacts.push(con.toModel());
        }

        return model;
    }
}
