














import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CYesNoDialog extends Vue {
    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: object;

    @Prop({
        type: Boolean,
    })
    public hideSave!: boolean;

    @Prop({
        default: "300px",
        type: String,
    })
    public dialogWidth!: string;
}
