




























































import PrintavoExportViewModel from "@/chipply/event/PrintavoExportViewModel";
import chipplyIcons from "@/chipply/ImportIcons";
import EditLayout from "@/components/layout/EditLayout.vue";
import CSuccessOrFailureCard from "@/components/ui/CSuccessOrFailureCard.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import Validation from "@/validation";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        CSuccessOrFailureCard,
        CToolbarBtn,
        EditLayout,
    },
})
export default class PrintavoExport extends Vue {
    public chipplyIcons = chipplyIcons;
    public name = "PrintavoExport";

    @Prop({
        type: Object,
    })
    public vm!: PrintavoExportViewModel | null;
    public Validation = Validation;
}
