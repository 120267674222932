















































































import Vue from "vue";
import Component from "vue-class-component";
import ITreeItem from "@/chipply/interface/i-tree-item";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        prop: "ids",
        event: "change",
    },
})
export default class TreeSelector extends Vue {
    public name = "TreeSelector";

    @Prop({
        type: String,
        required: false,
        default: "leaf",
    })
    public selection!: string;

    @Prop({
        type: Array,
    })
    public treeItems!: ITreeItem[];

    public selectedIds!: number[];
    public treeIds!: number[];
    public selectedItemsDisplay!: string;

    @Prop({
        type: Array,
    })
    public ids!: number[];

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    public clearable!: boolean;

    public independentSelection!: boolean;

    @Prop({
        type: String,
        required: false,
        default: "",
    })
    public customClass!: string;

    public initialTreeViewInput = true;

    @Prop({
        type: Boolean,
    })
    public validate!: boolean;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    public showMenu = false;

    @Prop({
        default: "400px",
    })
    public popupMaxHeight!: string;

    @Prop({
        default: 5,
    })
    public maxChipsDisplay!: number;

    @Prop({
        default: "100px",
    })
    public maxChipWidth!: string;

    @Prop({
        default: 0,
    })
    public maxLength!: number;

    @Prop({ type: String, default: "" })
    public label!: string;

    @Prop({ type: Boolean, default: false })
    public chips!: boolean;

    @Prop({ type: Boolean, default: false })
    public dense!: boolean;

    public $refs!: {
        treeView: Vue;
    };

    public data() {
        return {
            selectedItemsDisplay: undefined,
            selectAllChecked: false,
            selectedIds: this.ids,
            treeIds: [],
        };
    }

    public created() {
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
    }

    @Watch("ids")
    public onIdsChanged() {
        this.initialTreeViewInput = true;
        this.selectedIds = this.ids;
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
    }

    public treeViewInput(selectedIds: number[]) {
        this.selectedIds = selectedIds;
        this.updateSelectedItemsDisplay();
    }

    public subscribeToTreeView(open: boolean) {
        // if (open)
        //     this.$refs.treeView.$on("input", this.treeViewInput);
        // else
        //     this.$refs.treeView.$off("input", this.treeViewInput);
    }

    public beforeDestroy() {
        // this.$refs.treeView.$off("input", this.treeViewInput);
    }

    public updateSelectedItemsDisplay() {
        this.$emit("change", this.selectedIds);

        if (this.selectedIds.length === 0 || this.chips) {
            this.selectedItemsDisplay = "";
            return;
        }
        let selectedCategoriesDisplay = "";
        let originalFullDisplay = "";

        var index = 0;
        var hasMore = false;
        for (const catId of this.selectedIds) {
            index++;
            const catName = this.findCategoryName(this.treeItems, catId);
            if (catName) {
                if (selectedCategoriesDisplay.length > 0) {
                    selectedCategoriesDisplay += ", ";
                }

                if (this.maxLength > 0 && selectedCategoriesDisplay.length >= this.maxLength) {
                    originalFullDisplay = selectedCategoriesDisplay;
                    selectedCategoriesDisplay = `${selectedCategoriesDisplay.substring(0, this.maxLength)}...`;
                    hasMore = true;
                    index--;
                    break;
                }
                selectedCategoriesDisplay += catName;
                if (this.maxLength > 0 && selectedCategoriesDisplay.length >= this.maxLength) {
                    originalFullDisplay = selectedCategoriesDisplay;
                    selectedCategoriesDisplay = `${selectedCategoriesDisplay.substring(0, this.maxLength)}...`;
                    hasMore = true;
                    break;
                }
            }
        }

        if (hasMore) {
            if (this.selectedIds.length - index > 0) {
                selectedCategoriesDisplay += ` (+${this.selectedIds.length - index} others)`;
            } else {
                //display full text if there is no need to display "(+  others)"
                if (originalFullDisplay.length <= this.maxLength + "...(+  others)".length) {
                    selectedCategoriesDisplay = originalFullDisplay;
                }
            }
        }
        this.selectedItemsDisplay = selectedCategoriesDisplay;
    }

    get display() {
        if (!this.chips && this.selectedItemsDisplay) {
            return this.selectedItemsDisplay;
        }
        return "";
    }

    public isValid() {
        if (!this.validate) {
            return true;
        }
        return (this.selectedIds || []).length > 0;
    }

    public findCategoryName(categories: ITreeItem[], categoryId: number): string | null {
        if (!categories) {
            return null;
        }
        for (const cat of categories) {
            if (cat.id === categoryId) {
                if (cat.children && cat.children.length > 0) {
                    return null;
                }
                return cat.name;
            }

            for (const subcat of cat.children) {
                if (subcat.id === categoryId) {
                    return subcat.name;
                }
            }
        }

        return null;
    }

    private checkAll(checked: boolean) {
        const allCats = [];

        if (checked) {
            for (const cat of this.treeItems) {
                allCats.push(cat.id);

                for (const subcat of cat.children) {
                    allCats.push(subcat.id);
                }
            }
        }

        this.treeIds = allCats;
        this.selectedIds = allCats;
    }

    public clearSelection() {
        this.selectedIds = [];
        this.treeIds = [];
        this.updateSelectedItemsDisplay();
    }
}
