




































import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import CControlLabel from "@/components/ui/CControlLabel.vue";

@Component({
    components: {
        CControlLabel,
    },
    model: {
        event: "change",
        prop: "value",
    },
})
export default class SmsMergeEditor extends Vue {
    @Prop({
        type: String,
    })
    public mergeLabel!: string;

    public name = "SmsMergeEditor";

    @Prop({
        type: String,
    })
    public value!: string;

    public editingValue = this.value;

    @Prop({
        type: Number,
    })
    public maxLength!: number | null;

    @Prop({
        type: Array,
    })
    public mergeFields!: [];

    public defaultMaxLength = this.maxLength || 280;

    public $refs!: {
        textarea: Vue;
    };

    @Watch("value")
    protected valueChanged() {
        this.editingValue = this.value;
    }

    protected updateValue() {
        this.$emit("change", this.editingValue);
    }

    protected mergeFieldSelected(mergeField: string) {
        const formattedMergeField = `[${mergeField}] `;
        const areaElement = this.$refs.textarea.$el.querySelector("textarea");
        if (!areaElement) {
            return;
        }

        const value = areaElement.value;
        const startSelectionIndex = areaElement.selectionStart;
        let newValue = value.substr(0, startSelectionIndex);
        newValue += " " + formattedMergeField;
        newValue += value.substr(startSelectionIndex);
        this.editingValue = newValue;
        this.updateValue();
        areaElement.setSelectionRange(this.value.length - 1, this.value.length - 1);
        areaElement.focus();
    }

    protected lessThanMaxLength() {
        return this.value.length <= this.defaultMaxLength;
    }
}
