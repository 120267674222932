









































































import { WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import ProductColorSizeGrid from "@/components/products/ProductColorSizeGrid.vue";
import IProductInfo from "@/chipply/products/IProductInfo";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: { ProductColorSizeGrid },
})
export default class ProductMoreInfo extends Vue {
    public name = "ProductMoreInfo";
    public selectedOrderStatus: string | null = null;
    public productId!: number | undefined;

    @Prop({
        type: Number,
    })
    public providedId!: number | undefined;

    public product!: IProductInfo | undefined;

    @Prop({
        type: Boolean,
        default: false,
    })
    public useFeedProducts!: boolean;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("pid")) {
            this.productId = Number(urlParams.get("pid"));
            await this.loadProductInfo();
        } else {
            this.productId = this.providedId;
            await this.loadProductInfo();
        }
    }

    public clearData() {
        this.product = undefined;
        this.productId = undefined;
    }

    public data() {
        return {
            product: undefined,
            productId: undefined,
        };
    }

    @Watch("providedId")
    protected async onProductChange() {
        if (this.providedId != null) {
            this.productId = this.providedId;
            await this.loadProductInfo();
        }
    }

    protected async loadProductInfo() {
        const pathSegment = this.useFeedProducts ? "FeedProducts" : "EventProducts";
        const productData = (await WebHelper.getJsonData(
            `/api/${pathSegment}/MoreInfo/${this.productId}`
        )) as IProductInfo;

        this.product = productData;
    }
}
