import AccountingSummaryViewModel from "@/chipply/event/AccountingSummaryViewModel";
import { EventBus } from "@/chipply/EventBus";
import IAccountingSummaryResults from "@/chipply/interface/IAccountingSummaryResults";
import { IWorkOrderReportDownloadToPDF } from "@/chipply/reports/WorkOrder/IWorkOrderReportDownloadToPDF";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { WebHelper } from "chipply-common";
import { WorkOrderReportOptionsViewModel } from "@/chipply/reports/WorkOrder/WorkOrderReportOptionsViewModel";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";

export class AccountingSummaryPageViewModel extends PageViewModel {
    public accountingVm = new AccountingSummaryViewModel();
    public storeId: number;
    public catalogBatchId: number;
    public options!: WorkOrderReportOptionsViewModel | AccountingSummaryToggleOptions;

    constructor(storeId: number, catalogBatchId: number) {
        super();
        EventBus.$on("update-due-date", this.changeDueDate);
        this.storeId = storeId;
        this.catalogBatchId = catalogBatchId;
        this.accountingVm.eventId = storeId;
        this.accountingVm.catalogBatchId = catalogBatchId;
        this.options = new AccountingSummaryToggleOptions();
    }

    dispose() {
        EventBus.$off("update-due-date", this.changeDueDate);
    }

    public getReportArgs() {
        const pdfArgs = {
            storeId: this.storeId,
            catalogBatchId: this.catalogBatchId,
            toggleOptions: {
                includeAccountingSummary: true,
                includeAccountingSummaryNotes: true,
                includeAccountingSummaryManualAdjustmentsLeft: true,
                includeAccountingSummaryManualAdjustmentsRight: true,
                includeHandlingFeesExpanded: this.accountingVm.ShowDetailedHandlingFees,
                includeAccountingSummaryOperationalFees: true,
                includeAccountingSummaryPromoCodes: true,
            },
        } as IWorkOrderReportDownloadToPDF;

        return pdfArgs;
    }

    public async generateReport(includeHandlingFeesExpanded: boolean) {
        this.statusMessage = "Loading...";
        this.loading = true;

        const args = this.getReportArgs();

        if (args.toggleOptions) {
            args.toggleOptions.includeHandlingFeesExpanded = includeHandlingFeesExpanded;
        }

        try {
            const attachment = await WebHelper.postJsonDataBlob("/api/Reports/WorkOrderDownloadPDF", args);
            const filename = "AccountingSummary.pdf";

            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.errorMessage = "An error occurred while rendering the report.";
        } finally {
            this.loading = false;
            this.statusMessage = "";
        }
    }

    public async getStoreAccountingSummary(): Promise<void> {
        this.statusMessage = "Loading...";
        this.loading = true;
        let baseUrl = "/api/Events/GetAccountingSummary?eventId=";
        baseUrl += encodeURIComponent(`${this.storeId}`);
        try {
            const results = (await WebHelper.getJsonData(baseUrl)) as IAccountingSummaryResults;
            this.accountingVm.headerViewModel.sectionHeader = "FULL STORE ACCOUNT SUMMARY";
            this.accountingVm.assignFields(results);
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.loading = false;
        }
    }

    public async getCatalogBatchAccountingSummary() {
        this.statusMessage = "Loading...";
        this.loading = true;
        const url = `/api/AccountingSummary/GetCatalogBatchAccountingSummary/${this.catalogBatchId}`;
        try {
            const results = (await WebHelper.getJsonData(url)) as IAccountingSummaryResults;
            this.accountingVm.headerViewModel.sectionHeader = "CATALOG BATCH ACCOUNT SUMMARY";
            this.accountingVm.assignFields(results);
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.loading = false;
            this.statusMessage = "";
        }
    }

    public changeDueDate = async (args: { dueDate: string; storeId: number; processId: number }) => {
        if (args.processId) return;
        if (args.storeId !== this.storeId) return;

        this.statusMessage = "Loading...";
        this.loading = true;

        const updateArgs = {
            storeId: this.storeId,
            dueDate: args.dueDate,
        };

        try {
            await WebHelper.postJsonData("/api/Events/UpdateStoreDatesFromAccountingSummary", updateArgs);
        } catch {
            this.errorMessage = "An error occurred while updating the due date.";
        } finally {
            this.loading = false;
            this.statusMessage = "";
        }

        //this.showDueDateChangeWarning = false;
        await this.getStoreAccountingSummary();
    };
}
