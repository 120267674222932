


















import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class LongRunningOperationDialog extends Vue {
    public name = "LongRunningOperationDialog";

    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: String,
    })
    public statusMessage!: string;

    public data() {
        return {};
    }

    @Watch("show")
    private onShowChanged(oldValue: boolean, newValue: boolean) {
        const sideMenuBlocker = document.getElementById("sideMenuBlocker");
        if (sideMenuBlocker) {
            sideMenuBlocker.style.display = "";
        }
        const mainMenuBlocker = document.getElementById("mainMenuBlocker");
        if (mainMenuBlocker) {
            mainMenuBlocker.style.display = "";
        }
    }
}
