































import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CToolbarBtn extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: String,
    })
    public href!: string;

    @Prop({
        type: Boolean,
    })
    public outlined!: boolean;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: String,
    })
    public icon!: string;

    @Prop({
        type: Boolean,
    })
    public loading!: boolean;

    @Prop({
        type: String,
    })
    public tooltip!: string;

    @Prop({
        type: String,
    })
    public target!: string;

    @Prop({
        default: "primary",
        type: String,
    })
    public color!: string;

    @Prop({
        default: "",
        type: String,
    })
    public label!: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    public fab!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public hideBottomText!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public truncate!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public small!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public automationLabel!: string;
}
