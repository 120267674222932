


















import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CEditButton extends Vue {
    @Prop({
        type: Boolean,
    })
    public block!: boolean;

    public chipplyIcons = chipplyIcons;

    @Prop({
        type: String,
    })
    public color: string | undefined;

    @Prop({
        type: Boolean,
    })
    public icon!: boolean;

    public xSmall!: boolean | null;

    @Prop({
        type: Boolean,
    })
    public small!: boolean | null;

    @Prop({
        type: String,
    })
    public tooltip!: string | null;

    @Prop({
        type: Boolean,
    })
    public outlined!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public disabled!: boolean;

    protected computedColor() {
        if (this.color) {
            return this.color;
        }
        return "primary";
    }

    protected computedTooltip() {
        return this.tooltip || "Edit";
    }
}
