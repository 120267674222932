import { render, staticRenderFns } from "./ErrorReporter.vue?vue&type=template&id=f97bf726&scoped=true"
import script from "./ErrorReporter.vue?vue&type=script&lang=ts"
export * from "./ErrorReporter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f97bf726",
  null
  
)

export default component.exports