

















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import CDecisionCard from "../ui/CDecisionCard.vue";

@Component({
    components: {
        CDecisionCard,
    },
})
export default class CSaveDialog extends Vue {
    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: object;

    @Prop({
        type: Boolean,
    })
    public hideSave!: boolean;
}
