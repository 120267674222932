





















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import { DateTime, Interval } from "luxon";
import { PropType } from "vue";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CDatePicker extends Vue {
    @Prop({ type: Array as PropType<string[]> })
    public value!: string[];
    @Prop({ type: Boolean })
    public hideDetails!: boolean;
    @Prop()
    public label!: string;
    @Prop({ default: chipplyIcons.mdiCalendar, type: String })
    public icon!: string;
    @Prop({ type: String })
    public color!: string;
    @Prop({ type: Boolean })
    public dense!: boolean;
    @Prop({ type: Number })
    public nudgeRight!: number;
    public inputValue: string | number | null = null;
    public showMenu = false;
    public today = DateTime.fromISO(DateTime.now().toISODate()).toFormat("yyyy-MM-dd");
    public nextMonth = DateTime.fromISO(DateTime.now().plus({ months: 1 }).startOf("month").toISODate()).toFormat(
        "yyyy-MM-dd"
    );
    public pickerValues: string[] = [this.today];
    public picker1: string[] = [this.today];
    public picker2: string[] = [];
    private picker1Year = DateTime.fromISO(DateTime.now().toISODate()).year;
    private picker2Year = DateTime.fromISO(DateTime.now().toISODate()).year;
    private expanded = false;
    private picker1Month = "";
    private picker2Month = "";
    public activePicker1 = "DAY";
    private activePicker2 = "";

    public getPicker1CustomHeader(vModel: string[]) {
        if (vModel.length === 0) {
            return "";
        }
        if (vModel.length === 2) {
            if (this.expanded) {
                return this.determineDateTitle(vModel, this.picker1Month);
            }
            const args = this.getMultiplePickerDateArgs(vModel);
            if (args!.smallestDate.year !== args!.largestDate.year && this.picker2.length === 0) {
                const datePickerYearTitle = this.getDatePickerYearTitle();
                datePickerYearTitle[0].innerHTML = `${args!.smallestDate.year} - ${args!.largestDate.year}`;
            }
            return `${args!.smallestDate.monthShort} ${args!.smallestDate.day} - ${args!.largestDate.monthShort} ${
                args!.largestDate.day
            }`;
        }
        const args = this.getSingleDateArgs(vModel);

        this.$nextTick(() => {
            const datePickerYearTitle = this.getDatePickerYearTitle();
            if (datePickerYearTitle[0].innerHTML !== `${this.picker1Year}`)
                datePickerYearTitle[0].innerHTML = `${this.picker1Year}`;
        });
        return `${args.month} ${args.day}`;
    }
    public determineDateTitle(vModel: string[], pickerMonth: string) {
        const args = this.getMultiplePickerDateArgs(vModel);
        const monthFormat1 = DateTime.fromISO(DateTime.fromFormat(pickerMonth, "yyyy-MM").toISODate()).monthLong;
        const monthFormat2 = DateTime.fromISO(DateTime.fromFormat(pickerMonth, "yyyy-MM-dd").toISODate()).monthLong;
        const yearFormat1 = DateTime.fromISO(DateTime.fromFormat(pickerMonth, "yyyy-MM-dd").toISODate()).year;
        const yearFormat2 = DateTime.fromISO(DateTime.fromFormat(pickerMonth, "yyyy-MM").toISODate()).year;
        const sameMonth = args!.smallestDate.month === monthFormat1 || args!.smallestDate.month === monthFormat2;
        const sameYear = args!.smallestDate.year === yearFormat1 || args!.smallestDate.year === yearFormat2;

        if (this.picker1.length === 0 || this.picker2.length === 0) {
            return `${args!.smallestDate.monthShort} ${args!.smallestDate.day} - ${args!.largestDate.monthShort} ${
                args!.largestDate.day
            }`;
        }
        if (sameMonth && sameYear) {
            return `${args!.smallestDate.monthShort} ${args!.smallestDate.day}`;
        } else {
            return `${args!.largestDate.monthShort} ${args!.largestDate.day}`;
        }
    }

    public getPicker2CustomHeader(vModel: string[]) {
        if (vModel.length === 0) {
            return "";
        }
        if (vModel.length === 2) {
            const args = this.getMultiplePickerDateArgs(vModel);
            if (args!.smallestDate.year !== args!.largestDate.year && this.picker1.length === 0) {
                const datePickerYearTitle = this.getDatePickerYearTitle();
                datePickerYearTitle[1].innerHTML = `${args!.smallestDate.year} - ${args!.largestDate.year}`;
            }
            return this.determineDateTitle(vModel, this.picker2Month);
        }
        const args = this.getSingleDateArgs(vModel);
        this.$nextTick(() => {
            const datePickerYearTitle = this.getDatePickerYearTitle();
            if (datePickerYearTitle[1].innerHTML !== `${this.picker2Year}`)
                datePickerYearTitle[1].innerHTML = `${this.picker2Year}`;
        });
        return `${args.month} ${args.day}`;
    }

    public picker1YearChange(year: number) {
        this.picker1Year = year;
        const datePickerYearTitle = this.getDatePickerYearTitle();
        datePickerYearTitle[0].innerHTML = `${this.picker1Year}`;
    }

    public picker2YearChange(year: number) {
        this.picker2Year = year;
        const datePickerYearTitle = this.getDatePickerYearTitle();
        datePickerYearTitle[1].innerHTML = `${this.picker2Year}`;
    }
    public setYearTitles() {
        const datePickerYearTitle = this.getDatePickerYearTitle();
        const bothYearsNonZero = this.picker1Year > 0 && this.picker2Year > 0;
        const bothYearsNotEqual = this.picker1Year !== this.picker2Year;
        const picker2NoSelection = this.picker2.length === 0;
        const largestYear = Math.max(this.picker1Year, this.picker2Year);
        const smallestYear = Math.min(this.picker1Year, this.picker2Year);

        if (!this.expanded && bothYearsNonZero && bothYearsNotEqual && !picker2NoSelection) {
            datePickerYearTitle[0].innerHTML = `${smallestYear} - ${largestYear}`;
        }
        if (this.expanded && bothYearsNotEqual) {
            datePickerYearTitle[0].innerHTML = `${this.picker1Year}`;
            datePickerYearTitle[1].innerHTML = `${this.picker2Year}`;
        }
    }
    private getDatePickerYearTitle() {
        return document.getElementsByClassName("v-picker__title__btn v-date-picker-title__year");
    }
    protected expand() {
        this.expanded = !this.expanded;
        this.$nextTick(() => {
            this.setYearTitles();
        });
        this.transferSelection();
    }
    protected picker1Changed(vModel: string[]) {
        this.checkPickerValues(vModel);
        if (this.picker2.length === 2) this.picker2 = [];
        if (this.pickerValues.length === 2) {
            this.picker1 = this.pickerValues;
            const withinMonthRange = this.isSelectionInSameMonth(this.picker1);
            // if the selection spans multiple months, this will sync both pickers
            if (this.picker1Month === this.picker2Month || (this.expanded && !withinMonthRange)) {
                this.picker2 = this.pickerValues;
                this.setYearTitles();
            }
        }
    }
    protected transferSelection() {
        if (this.picker1.length === 0 && this.picker2.length === 2) {
            this.picker1 = this.picker2;
            this.picker1Month = this.picker2Month;
            this.picker1YearChange(this.picker2Year);
            this.pickerValues = this.picker1;
            this.picker2 = [];
        }
    }
    protected isSelectionInSameMonth(vModel: string[]): boolean {
        const orderedDates = this.orderDates(vModel);
        const monthStart = DateTime.fromISO(DateTime.fromFormat(vModel[0], "yyyy-MM-dd").startOf("month").toISODate());
        const monthEnd = DateTime.fromISO(DateTime.fromFormat(vModel[0], "yyyy-MM-dd").endOf("month").toISODate());
        const interval = Interval.fromDateTimes(monthStart, monthEnd);
        return orderedDates.earliestDate >= interval.start && orderedDates.latestDate <= interval.end;
    }
    protected picker2Changed(vModel: string[]) {
        this.checkPickerValues(vModel);
        if (this.picker1.length === 2) this.picker1 = [];
        if (this.picker1.length === 1) this.picker1 = this.pickerValues;
        if (this.pickerValues.length === 2) {
            this.picker2 = this.pickerValues;
            this.setYearTitles();
        }
    }

    public checkPickerValues(vModel: string[]) {
        if (this.pickerValues.length >= 2) {
            this.pickerValues = [];
        }
        this.pickerValues.push(...vModel);
        // create an object to keep track of the individual dates and how many times they occur in pickerValues
        const counts: any = {};
        for (const date of this.pickerValues) {
            // assign the date as key and assign the count of occurrences as value
            counts[date] = counts[date] ? counts[date] + 1 : 1;
            // if we have a date that is listed more than 2 times, remove it.
            // this will ensure that we always have a maximum of 2 values in the pickerValues array.
            if (counts[date] >= 2 && this.pickerValues.length > 2) {
                const index = this.pickerValues.indexOf(date);
                this.pickerValues.splice(index, 1);
            }
        }
    }
    protected clear() {
        this.picker1 = [];
        this.picker2 = [];
        this.pickerValues = [];
        const datePickerYearTitle = this.getDatePickerYearTitle();
        if (datePickerYearTitle[0]) {
            datePickerYearTitle[0].innerHTML = `${this.picker1Year}`;
            if (datePickerYearTitle[1]) {
                datePickerYearTitle[1].innerHTML = `${this.picker2Year}`;
            }
        }
    }

    public getMultiplePickerDateArgs(vModel: string[]) {
        if (vModel.length === 2) {
            const orderedDates = this.orderDates(vModel);
            const smallestDate = {
                date: orderedDates.earliestDate,
                month: orderedDates.earliestDate.monthLong,
                monthShort: orderedDates.earliestDate.monthShort,
                day: orderedDates.earliestDate.day,
                year: orderedDates.earliestDate.year,
            };
            const largestDate = {
                date: orderedDates.latestDate,
                month: orderedDates.latestDate.monthLong,
                monthShort: orderedDates.latestDate.monthShort,
                day: orderedDates.latestDate.day,
                year: orderedDates.latestDate.year,
            };
            return { smallestDate, largestDate };
        }
    }
    public getSingleDateArgs(vModel: string[]) {
        const date = DateTime.fromISO(vModel[0]);
        return { month: date.monthShort, day: date.day, year: date.year };
    }
    public orderDates(vModel: string[]) {
        const earliestDate = DateTime.fromISO(
            DateTime.fromFormat(
                vModel.reduce(function (a, b) {
                    return a < b ? a : b;
                }),
                "yyyy-MM-dd"
            ).toISODate()
        );
        const latestDate = DateTime.fromISO(
            DateTime.fromFormat(
                vModel.reduce(function (a, b) {
                    return a > b ? a : b;
                }),
                "yyyy-MM-dd"
            ).toISODate()
        );
        return { earliestDate, latestDate };
    }

    public get pickerValueDisplay() {
        if (!this.pickerValues) return "";
        if (this.pickerValues.length < 2) {
            return "";
        } else {
            const earliestDate = this.pickerValues.reduce(function (a, b) {
                return a < b ? a : b;
            });
            const latestDate = this.pickerValues.reduce(function (a, b) {
                return a > b ? a : b;
            });
            return `${earliestDate} ~ ${latestDate}`;
        }
    }
    public mounted() {
        this.onValueChanged();
    }
    @Watch("value")
    public onValueChanged() {
        if (this.value.length === 0) {
            return;
        }
        this.pickerValues = this.value;
    }
    @Watch("pickerValues")
    public onInputValueChanged() {
        this.$emit("change", this.pickerValues);
    }
    @Watch("showMenu")
    public menuChanged() {
        this.expanded = false;
        this.transferSelection();
        this.activePicker1 = "DATE";
        this.setYearTitles();
        if (
            (this.picker1.length === 1 && this.picker2.length === 0) ||
            (this.picker1.length === 0 && this.picker2.length === 0)
        ) {
            const dateTime = DateTime.fromISO(DateTime.now().toISODate());
            this.picker1Month = dateTime.toFormat("yyyy-MM-dd");
            if (!this.picker1.includes(this.today)) this.picker1.push(this.today);
            this.$nextTick(() => {
                this.picker1YearChange(dateTime.year);
            });
        }
    }
}
