






















import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import ListLayout from "@/components/layout/ListLayout.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CDeleteDialog from "../../dialogs/CDeleteDialog.vue";
import ScrollableContentMixin from "../../ScrollableContentMixin";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import { SearchStoreArtworksViewModel } from "@/chipply/event/artworks/SearchStoreArtworksViewModel";
import { IListStoreArtworkFilters } from "@/chipply/event/artworks/IStoreArtworkDto";
import CDecisionCard from "../../ui/CDecisionCard.vue";
import CDate from "@/components/ui/CDate.vue";
import CApiAutoComplete from "@/components/ui/CApiAutoComplete.vue";
import { Autocompletes } from "@/chipply/Autocompletes";
import CDataTable from "@/components/ui/CDataTable.vue";
import ArtworkFilters from "./ArtworkFilters.vue";
import SearchArtworkDataTable from "./SearchArtworkDataTable.vue";

@Component({
    components: {
        CApiAutoComplete,
        ListLayout,
        CToolbarBtn,
        CEditButton,
        CDeleteDialog,
        CDecisionCard,
        CDate,
        CDataTable,
        ArtworkFilters,
        SearchArtworkDataTable,
    },
})
export default class SearchStoreArtworks extends mixins(ScrollableContentMixin) {
    @Prop({
        type: Number,
    })
    public dealerId!: number;

    @Prop({
        type: Number,
    })
    public storeId!: number;

    @Prop({
        type: Object,
    })
    public searchArtworksState!: IPageReferrerSettings | undefined;

    public name = "SearchStoreArtworks";
    public icons = chipplyIcons;

    @Prop({ type: Object })
    public vm!: SearchStoreArtworksViewModel;

    public Autocompletes = Autocompletes;
    public declare $refs: {
        searchArtworksTable: ListLayout;
    };
    public get hasImportArtworkDialogViewModel() {
        return this.vm && this.vm.importArtworkDialogViewModel != null;
    }

    public async created() {
        if (this.searchArtworksState) {
            if (this.searchArtworksState.filters) {
                this.vm.filters = this.searchArtworksState.filters as IListStoreArtworkFilters;
            }
            this.vm.pagination = this.searchArtworksState.pagination;
            //TODO
            //Switch to the first page currently
            //There are no logic to move to previous page in ListLayout
            if (this.vm.pagination) {
                this.vm.pagination.page = 1;
            }
        }

        await this.initialize();
        await this.vm.getArtworkVendorInfo();
    }

    public async initialize() {
        if (!this.storeId) {
            return;
        }
        this.vm.storeId = this.storeId;
    }

    @Watch("storeId")
    public async onStoreIdIdChanged() {
        await this.initialize();
    }

    @Watch("dealerId")
    public async onDealerIdChanged() {
        await this.initialize();
    }
}
