import { render, staticRenderFns } from "./NavigationSide.vue?vue&type=template&id=4b5f9a60&scoped=true"
import script from "./NavigationSide.vue?vue&type=script&lang=ts"
export * from "./NavigationSide.vue?vue&type=script&lang=ts"
import style0 from "./NavigationSide.vue?vue&type=style&index=0&id=4b5f9a60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b5f9a60",
  null
  
)

export default component.exports