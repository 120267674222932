


































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Utils } from "chipply-common";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CMoney extends Vue {
    public Utils = Utils;
    public inputValue: string | null = null;

    @Prop({
        type: [String, Number],
    })
    public value: string | number | undefined;

    @Prop({
        type: Boolean,
    })
    public allowNull!: boolean;

    @Prop({
        type: String,
    })
    public backgroundColor!: string;

    @Prop({
        type: Boolean,
    })
    public dense!: boolean;

    @Prop({
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        type: [String, Number],
    })
    public height!: string | number;

    @Prop({
        type: Boolean,
    })
    public hideDetails!: boolean;

    @Prop({
        type: String,
    })
    public label!: string;

    @Prop({
        type: [String, Number],
    })
    public maxLength!: string | number;

    @Prop({
        type: Boolean,
    })
    public required!: boolean;

    @Prop({
        type: Array,
    })
    public rules!: [];

    @Prop({
        type: Boolean,
    })
    public validateOnBlur!: boolean;

    @Prop({
        type: Boolean,
    })
    public percentage!: boolean;

    @Prop({
        type: Boolean,
    })
    public readonly!: boolean;

    @Prop({
        type: Boolean,
    })
    public solo!: boolean;

    @Prop({
        type: Boolean,
    })
    public rounded!: boolean;

    @Prop({
        type: String,
        default: "",
    })
    public automationLabel!: string;

    public created() {
        this.inputValue = this.getValue();
    }

    @Watch("value")
    private valueChanged() {
        this.inputValue = this.getValue();
    }

    private getValue() {
        if (this.allowNull) {
            return Utils.isNullOrUndefined(this.value) ? null : Utils.getCurrencyValue(this.value || 0);
        }
        return Utils.getCurrencyValue(this.value || 0);
    }

    private numberFocused(event: FocusEvent) {
        if (!event) {
            return;
        }
        (event.currentTarget as HTMLInputElement).select();
    }

    private numberChanged(value: string) {
        const formattedValue = Utils.getCurrencyValue(value || 0);
        this.$emit("change", Utils.parseNumber(formattedValue));
        this.inputValue = formattedValue;
    }

    private numberBlurred() {
        this.$emit("blur");
    }
}
