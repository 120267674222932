














import CCard from "@/components/ui/CCard.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { AsyncInteractionWithDataViewModel } from "chipply-common";

@Component({
    components: { CCard },
    directives: { mask },
})
export default class CBooleanSetterCard extends Vue {
    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: AsyncInteractionWithDataViewModel<boolean>;

    @Prop({
        type: String,
    })
    public title!: string;

    @Prop({
        type: String,
    })
    public label!: string;

    public cancel() {
        this.viewModel.done("cancel");
    }

    public accept() {
        this.viewModel.done("continue");
    }
}
