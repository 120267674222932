






















import { FieldRepositoryListResults } from "@/chipply/fields/FieldRepositoryListResults";
import { StoresMenuData } from "@/chipply/navigation/StoresMenuData";
import ErrorReporter from "@/components/utility/ErrorReporter.vue";
import NavigationArea from "@/chipply/NavigationArea";
import NavigationSide from "@/components/navigation/NavigationSide.vue";
import NavigationTop from "@/components/navigation/NavigationTop.vue";
import { Serializer, typeDependencies, WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as userInfoStore from "@/store/UserInfoStore";
import * as envStore from "@/store/EnvironmentStore";
import { EventBus } from "@/chipply/EventBus";
import IMenuData from "@/chipply/IMenuData";

@Component({
    components: { NavigationSide, NavigationTop, ErrorReporter },
})
@typeDependencies({
    types: {
        StoresMenuData,
    },
})
export default class NavigationHost extends Vue {
    public name = "NavigationHost";
    public menuData!: IMenuData;

    @Prop({
        type: [Object, Number],
    })
    public menuArgs!: object;

    @Prop({
        type: Boolean,
    })
    public init!: boolean;

    @Prop({
        type: Number,
    })
    public area!: number;

    @Prop({
        type: Number,
    })
    public userLevel!: number | null;

    public errorMessage!: string;
    public errorReporterOpened!: boolean;

    @Prop({
        type: Boolean,
    })
    public noScroll!: boolean;

    public preventNavigation!: boolean;
    public getMenuDataCalled!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isNavigationSideVisible!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public backgroundColor!: string;

    public async created() {
        await userInfoStore.initialize(this.$store);
        await envStore.initialize(this.$store);

        if (this.init) {
            await this.getMenuData();
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("errorMessage")) {
            this.errorMessage = urlParams.get("errorMessage") as string;
            this.errorReporterOpened = true;
        }

        EventBus.$on("update-menu-data", this.getMenuData);
        EventBus.$on("store-settings-updated", this.updateMenuData);
    }

    public data() {
        return {
            NavigationArea,
            errorMessage: undefined,
            errorReporterOpened: false,
            menuData: {},
            preventNavigation: false,
        };
    }

    @Watch("init")
    protected async onInitChanged(nv: boolean, ov: boolean) {
        if (nv) {
            await this.getMenuData();
        }
    }

    protected async getMenuData() {
        if (this.getMenuDataCalled) {
            return;
        }
        this.getMenuDataCalled = true;
        await this.updateMenuData();
    }

    protected async updateMenuData() {
        this.getMenuDataCalled = true;

        const menuData = await WebHelper.postJsonData("/api/Menu/GetMenuData", {
            area: this.area,
            menuArgs: this.menuArgs,
        });
        if (menuData && menuData !== "null") {
            this.menuData = Serializer.deserialize(menuData);
        }

        this.preventNavigation = (this.menuData as any)?.preventNavigation || false;
        this.menuData.area = this.area;
        EventBus.$emit("menu-data-complete", this.menuData);

        // for the stores area, update store count badges after the initial menudata has loaded
        if (this.area == NavigationArea.Stores) {
            const menuData = await WebHelper.postJsonData("/api/Menu/GetStoreBadgeCountData", {
                area: this.area,
                menuArgs: this.menuArgs,
            });
            if (menuData && menuData !== "null") {
                this.menuData = Serializer.deserialize(menuData);
            }
            this.preventNavigation = (this.menuData as any)?.preventNavigation || false;
            this.menuData.area = this.area;
            EventBus.$emit("menu-data-complete", this.menuData);
        }
    }

    protected get contentStyle() {
        return (
            "height: 100%; width: 100%;overflow:" +
            (this.noScroll ? "hidden;" : "auto;") +
            (this.backgroundColor ? "background-color: " + this.backgroundColor : "")
        );
    }
}
