import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, champroShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "ChamproPurchaseOrderSettings" })
export default class ChamproPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public vendorName = "champro";
    public shippingCustomerAccount = "";

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return champroShipMethods;
    }
}
