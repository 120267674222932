











































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NavigationSideHeader extends Vue {
    public name = "NavigationSideHeader";

    @Prop({
        type: String,
    })
    public text!: string;

    @Prop({
        type: Boolean,
    })
    public noOverflow!: boolean;
}
