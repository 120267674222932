
































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { AsyncInteractionViewModel, Utils } from "chipply-common";
import { Prop } from "vue-property-decorator";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";
import CChildPane from "@/components/ui/CChildPane.vue";
import EditProductDetailColorsViewModel from "@/chipply/products/edit/EditProductDetailColorsViewModel";
import ColorTextField from "@/components/input/ColorTextField.vue";
import CHelpButton from "@/components/ui/CHelpButton.vue";
import IProductDetailColorModel from "@/chipply/products/edit/IProductDetailColorModel";
import EditProductDetailColorEditor from "./EditProductDetailColorEditor.vue";
import Sortable, { SortableEvent } from "sortablejs";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CDeleteDialog from "@/components/dialogs/CDeleteDialog.vue";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
@Component({
    components: {
        CHelpButton,
        ColorTextField,
        CChildPane,
        EventProductColorSizeGrid,
        CToolbarBtn,
        EditLayout,
        EditProductDetailColorEditor,
        CDeleteButton,
        CDeleteDialog,
        CSaveDialog,
    },
})
export default class EditProductDetailColors extends Vue {
    public chipplyIcons = chipplyIcons;
    public tableHeight: string | null = null;
    public isDirty = false;

    @Prop({
        default: new EditProductDetailColorsViewModel(),
        type: Object,
    })
    public vm!: EditProductDetailColorsViewModel;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    public async closeChildSizes() {
        this.$emit("close");
    }

    public $refs!: {
        tableContainer: HTMLDivElement;
    };

    public Utils = Utils;

    public async created() {
        await this.vm.getColors();
        if (this.vm.isAdminUser) {
            await this.vm.getStandardColors();
        }

        this.$nextTick(() => {
            this.initializeDragAndDrop("colorTable");
        });
    }

    public get colorHeader() {
        const checkColWidth = "50px";
        const headers = [
            { text: "", value: "dragHandle" },
            { text: "", value: "edit" },
            { text: "Color Name", value: "colorName", width: "auto" },
        ];
        if (this.vm.isAdminUser) {
            headers.push({ text: "Standard Color Name", value: "standardColors.standardColorName" });
        }
        headers.push(
            ...[
                { text: "Image 1", value: "image1Url" },
                { text: "Hide", value: "blankColor1", width: checkColWidth },
                { text: "Delete", value: "delete1", width: checkColWidth },
                { text: "Image 2", value: "image2Url", width: "80px" },
                { text: "Hide", value: "blankColor2", width: checkColWidth },
                { text: "Delete", value: "delete2", width: checkColWidth },
                { text: "Image 3", value: "image3Url" },
                { text: "Hide", value: "blankColor3", width: checkColWidth },
                { text: "Delete", value: "delete3", width: checkColWidth },
                { text: "Image 4", value: "image4Url" },
                { text: "Hide", value: "blankColor4", width: checkColWidth },
                { text: "Delete", value: "delete4", width: checkColWidth },
                { text: "Primary", value: "hexCode1" },
                { text: "Secondary", value: "hexCode2" },
                { text: "", value: "delete" },
            ]
        );
        return headers;
    }

    public async quickAddColors() {
        const wasOnlyOneColor = this.vm.oneColor !== null && this.vm.oneColor !== undefined;
        await this.vm.quickAddColors();
        if (wasOnlyOneColor) {
            this.$nextTick(() => {
                this.initializeDragAndDrop("colorTable");
            });
        }
    }

    protected initializeDragAndDrop(tableId: string) {
        if (this.vm.oneColor) {
            return;
        }
        const table = document.getElementById("colorTable");
        const tableBody = table!.querySelector("tbody");

        Sortable.create(tableBody!, {
            handle: ".dragHandle",
            //filter: ".workOrderNotes",
            preventOnFilter: false,
            onStart: (event: SortableEvent) => {},
            onEnd: (event: SortableEvent) => {
                const oldIndex = event.oldIndex!;
                const newIndex = event.newIndex!;
                const tempArray: IProductDetailColorModel[] = [];
                tempArray.push(...this.vm.existingColors!);

                const rowSelected = tempArray.splice(oldIndex, 1)[0];
                tempArray.splice(newIndex, 0, rowSelected);
                this.vm.existingColors! = [];
                // Goofy but necessary to correctly redraw after drag and drop
                this.$nextTick(() => {
                    this.vm.existingColors = tempArray;
                });
            },
        });
    }
}
