import { render, staticRenderFns } from "./EcomOrdersGrid.vue?vue&type=template&id=56da500a&scoped=true"
import script from "./EcomOrdersGrid.vue?vue&type=script&lang=ts"
export * from "./EcomOrdersGrid.vue?vue&type=script&lang=ts"
import style0 from "./EcomOrdersGrid.vue?vue&type=style&index=0&id=56da500a&prod&scoped=true&lang=css"
import style1 from "./EcomOrdersGrid.vue?vue&type=style&index=1&id=56da500a&prod&lang=css"
import style2 from "./EcomOrdersGrid.vue?vue&type=style&index=2&id=56da500a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56da500a",
  null
  
)

export default component.exports