


































































import chipplyIcons from "@/chipply/ImportIcons";
import Component from "vue-class-component";
import Vue from "vue";
import IProcessOptionItem from "@/chipply/process/IProcessOptionItem";
import ProcessOptionItem from "@/chipply/process/ProcessOptionItem";
import CMoney from "@/components/ui/CMoney.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import Validation from "@/validation";
import { Prop } from "vue-property-decorator";
@Component({
    components: {
        CDeleteButton,
        CMoney,
        CAddButton,
        CControlLabel,
        TextHeading,
    },
})
export default class ProcessOptionEditor extends Vue {
    public Validation = Validation;
    public chipplyIcons = chipplyIcons;
    public isValid = false;

    @Prop({
        type: Array,
    })
    public options!: IProcessOptionItem[];

    public $refs!: {
        optionsList: Vue;
        optionForm: any;
    };

    protected quickOptions: string | null = null;

    public mounted() {
        this.$refs.optionForm.validate();
    }

    protected addOption(value?: string) {
        const item = new ProcessOptionItem();
        item.name = value ?? "";
        this.options.push(item);
        this.$nextTick(() => {
            this.$refs.optionForm.validate();
            // Hacky but it works...
            const table = this.$refs.optionsList.$el as HTMLDivElement;
            (table.firstChild! as HTMLDivElement).scrollTo(0, table.scrollHeight + table.offsetHeight);
        });
    }

    protected removeOption(option: IProcessOptionItem) {
        const optionIndex = this.options.indexOf(option);
        this.options.splice(optionIndex, 1);
    }

    protected quickAddOptions() {
        if (!this.quickOptions) {
            return;
        }
        const entries = this.quickOptions.split("\n");

        for (const entry of entries) {
            if (!entry) {
                continue;
            }
            this.addOption(entry);
        }

        this.quickOptions = null;
    }

    private close(accepted: boolean) {
        this.$emit("close", accepted);
    }
}
