



























import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CHtmlViewEdit extends Vue {
    public isInEditMode = false;
    public inputValue: string | null = null;

    @Prop({
        type: Array,
    })
    public rules!: [];

    @Prop({
        type: String,
    })
    public value!: string | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    public forceUnderline!: boolean;

    public mounted() {
        this.onValueChanged();
    }

    @Watch("isInEditMode")
    public isInEditModeChanged(newValue: boolean, oldValue: boolean) {
        if (newValue) {
            this.$nextTick(() => {
                const vTextArea = this.$refs.textarea as any;
                vTextArea.focus();
            });
        }
    }
    @Watch("value")
    public onValueChanged() {
        this.inputValue = this.value;
    }

    @Watch("inputValue")
    public onInputValueChanged() {
        this.$emit("change", this.inputValue);
    }
}
