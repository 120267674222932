








import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CancelSaveButtonBarMobile extends Vue {
    public name = "CancelSaveButtonBarMobile";
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Boolean,
    })
    public invalid!: boolean;

    @Prop({
        type: Boolean,
    })
    public hideCancel!: boolean;

    protected close(accepted: boolean) {
        this.$emit("close", accepted);
    }
}
