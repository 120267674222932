













































import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import Validation from "@/validation";
import { IOrderTax, TaxType, WebHelper } from "chipply-common";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        TaxTypeSelection,
        TextHeading,
    },
})
export default class EditOrderTaxSettings extends Vue {
    public name = "EditOrderTaxSettings";

    @Prop({
        type: Number,
    })
    public orderId: number | undefined;

    @Prop({
        type: Object,
    })
    public order!: IOrderTax | null;
    public isValid!: boolean;

    public $refs!: { form: any };

    public async created() {
        await this.initialize();
    }

    public async initialize() {
        if (this.orderId) {
            const results = (await WebHelper.getJsonData(
                `/api/EcomOrder/GetOrderTax/${this.orderId}`,
                true
            )) as IOrderTax;
            this.order = results;
        }
        this.$nextTick(() => {
            this.$refs.form.validate();
        });
    }

    public data() {
        return {
            Validation,
            isValid: false,
            taxMethods: [
                { text: "Tax If Order Has Taxable Item", value: 0 },
                { text: "Always Tax", value: 1 },
                { text: "Never Tax", value: 2 },
            ],
        };
    }

    protected selectedTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.order!.selectedTaxType = taxSettings.taxType;
        this.order!.taxRate = taxSettings.taxRate;
    }

    protected selectedShippingTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.order!.shippingTaxMethod = taxSettings.taxType;
        this.order!.shippingTaxRate = taxSettings.taxRate;
    }

    protected selectedHandlingTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.order!.handlingTaxMethod = taxSettings.taxType;
        this.order!.handlingTaxRate = taxSettings.taxRate;
    }

    protected close(accepted: boolean) {
        this.$emit("close", accepted);
    }
}
