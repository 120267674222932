








import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import NavigationArea from "@/chipply/NavigationArea";
import NavigationHost from "@/components/navigation/NavigationHost.vue";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import EditProcess from "@/components/process/EditProcess.vue";
import StorePageMixin from "@/components/page/StorePageMixin";
import { Prop } from "vue-property-decorator";
import EventProductColorSizeGrid from "../products/EventProductColorSizeGrid.vue";

@Component({
    components: { EventProductColorSizeGrid, EditProcess, NavigationHost },
})
export default class EventProductColorSizeGridPage extends Vue {
    @Prop({
        type: Number,
    })
    public eventProductId!: number;

    protected close(result: any) {
        this.$emit("close", result);
    }
}
